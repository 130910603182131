import React from 'react';
import './assets/css/app.css';
import TimeAgo from 'javascript-time-ago';
import fa from 'javascript-time-ago/locale/fa';

import { BrowserRouter as Router, Switch } from 'react-router-dom';
import ProtectedRoute from './components/Router/ProtectedRoute';
import DashboardPage from './pages/dashboard.page';
import PublicOnlyRoute from './components/Router/PublicOnlyRoute';
import LoginPage from './pages/login.page';
import HeaderNav from './components/Navigation/HeaderNav';
import { DrawerProvider } from './contexts/drawer.context';
import { LoadingProvider } from './contexts/loading.context';
import DevicePage from './pages/device.page';
import { CurrentDeviceProvider } from './contexts/current-device.context';

TimeAgo.addDefaultLocale(fa);

function App() {
  return (
    <LoadingProvider>
      <DrawerProvider>
        <CurrentDeviceProvider>
          <Router>
            <div className="app px-2">
              <HeaderNav />
              <main className="py-16 container mx-auto">
                <Switch>
                  <ProtectedRoute exact path="/" component={DashboardPage} />
                  <ProtectedRoute exact path="/device" component={DevicePage} />
                  <PublicOnlyRoute exact path="/login" component={LoginPage} />
                </Switch>
              </main>
            </div>
          </Router>
        </CurrentDeviceProvider>
      </DrawerProvider>
    </LoadingProvider>
  );
}

export default App;
