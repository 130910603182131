import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { selectAuthorization } from '../../store/authorization';
import { useSelector } from 'react-redux';

const ProtectedRoute = ({ component, ...rest }: any) => {
    const authorization = useSelector(selectAuthorization);

    const routeComponent = (props: any) => (
        authorization.token !== '' || authorization.expire_at > new Date()
            ? React.createElement(component, props)
            : <Redirect to={{ pathname: '/login' }} />
    );
    return <Route {...rest} render={routeComponent} />;
};
export default ProtectedRoute;