import { PointOptionsObject } from 'highcharts';
import moment from 'moment-timezone';
import { useState } from 'react';
import { useContext, useEffect } from 'react';
import { ChevronLeft } from 'react-bootstrap-icons';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import HumidityChart from '../components/Charts/HumidityChart';
import RangeSelector, { ChartRangeSelector } from '../components/Charts/RangeSelector';
import TemperatureChart from '../components/Charts/TemperatureChart';
import DeviceReportList from '../components/Reports/DeviceReportList';
import { CurrentDeviceProviderContext } from '../contexts/current-device.context';
import { LoadingProviderContext } from '../contexts/loading.context';
import { deviceGQL } from '../graphql/device/handlers';
import { PacketsIncludedFields, PacketsInputDTO } from '../graphql/packets/dto';
import { packetsGQL } from '../graphql/packets/handlers';
import { SORT } from '../graphql/types';
import { Device } from '../interfaces/device';
import { selectAuthorization } from '../store/authorization';
import { updateDevice } from '../store/devices/action';

export interface ChartRangeData {
    temperatures: Array<PointOptionsObject>;
    humidities: Array<PointOptionsObject>;
}
export default function DevicePage() {
    const { setLoading } = useContext(LoadingProviderContext);

    // Monthly and weekly state holder for charts
    const [rangeData, setRangeData] = useState<ChartRangeData>({ temperatures: [], humidities: [] });

    const [selectedRange, setSelectedRange] = useState<ChartRangeSelector>('REALTIME')
    // current device state
    const { currentDevice, setCurrentDevice } = useContext(CurrentDeviceProviderContext);
    // instance of browser history for redirection to dashboard home.
    const history = useHistory();

    const authorization = useSelector(selectAuthorization);
    const dispatch = useDispatch();

    const changeRange = async (selected: ChartRangeSelector) => {
        if (currentDevice) {
            selected !== selectedRange && setSelectedRange(selected);
            const after = moment().subtract(selected === 'MONTHLY' ? 30 : 7, 'days').utc().format();
            setLoading(true);

            const input: PacketsInputDTO = {
                device_id: currentDevice.id,
                after: after,
                sort: SORT.ASC,
                include: [PacketsIncludedFields.TEMPERATURES, PacketsIncludedFields.HUMIDITIES],
            }
            const result = await packetsGQL(input);
            if (result.success && result.temperatures && result.humidities) {
                setRangeData({
                    temperatures: result.temperatures.map((element) => ([moment(element.created_at).local().toDate(), element.value])),
                    humidities: result.humidities.map((element) => ([moment(element.created_at).local().toDate(), element.value]))
                });
            }
            setSelectedRange(selected);
            setLoading(false);
        }
    }

    useEffect(() => {
        if (authorization.expire_at > new Date() && authorization.token !== '' && currentDevice) {
            (async () => {
                const result = await deviceGQL(currentDevice.id);
                if (result) {
                    if (result.success) {
                        dispatch(updateDevice(result.device as Device));
                        setCurrentDevice(result.device as Device)
                    }
                }
            })();
        }
    }, [authorization, dispatch, currentDevice, setCurrentDevice]);

    const backPress = () => {
        setCurrentDevice(undefined);
        history.replace('/');
    }

    return (
        <>
            <div className="no-print flex flex-row-reverse justify-between items-center pb-2">
                <div className="font-bold">{currentDevice?.display_name}</div>
                <button className="flex space-x-2 p-1 items-center justify-center rounded-full bg-gray-100 dark:bg-gray-700 hover:bg-blue-500 dark:hover:bg-blue-500 hover:text-white dark:hover:text-white"
                    onClick={backPress}>
                    <ChevronLeft size={18} />
                    <div className="pr-2">داشبورد</div>
                </button>
            </div>
            {currentDevice ?
                <div className="bg-gray-100 dark:bg-gray-900 rounded-lg overflow-hidden">
                    <RangeSelector selected={selectedRange} setSelected={changeRange} />
                    {
                        selectedRange === 'REPORT' ?
                            <DeviceReportList /> :
                            <>
                                <TemperatureChart device={currentDevice} temperatures={rangeData.temperatures.length > 0 ? rangeData.temperatures : undefined} />
                                <HumidityChart device={currentDevice} humidities={rangeData.humidities.length > 0 ? rangeData.humidities : undefined} />
                            </>
                    }
                </div>
                : null}
        </>
    )
}