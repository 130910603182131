import { MqttMessage } from "../../types/mqtt";
import GraphQLBaseResult from "../gql-base.dto";
import { SORT } from "../types";

// @ENUM of allowed include fields of packets query result
export enum PacketsIncludedFields {
    TEMPERATURES = 'TEMPERATURES',
    HUMIDITIES = 'HUMIDITIES',
    PACKETS = 'PACKETS',
}

export interface PacketsInputDTO {
    device_id: string;
    after: string;
    sort: SORT
    include: Array<PacketsIncludedFields>
}

export interface PacketsResultDTO extends GraphQLBaseResult {
    size: number;
    packets?: Array<MqttMessage>;
    temperatures?: Array<PacketResultItems>;
    humidities?: Array<PacketResultItems>;
}

export interface PacketResultItems {
    created_at: Date;
    value: number;
    out_of_range: boolean;
    unit: string;
    type: string;
    device_id: string;
}