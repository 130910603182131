import { MqttMessage } from '../../types/mqtt';
import { AnyAction } from 'redux';
import { RootState } from '..';
import { MESSAGES_KEY } from '../../types';

export interface MqttPacketState {
    messages: Array<MqttMessage>
}
// declear redux action types name for devices actions
export enum MqttActionTypes {
    NEW_PACKET = '@@mqtt/NEW_PACKET',
}

// declear devices state selector function
export const selectMqttMessage = (rootState: RootState) =>
    rootState.mqtt;

const initialState = (): MqttPacketState => {
    const data = localStorage.getItem(MESSAGES_KEY);
    if (data) {
        return { messages: JSON.parse(data) as Array<MqttMessage> }
    }
    return { messages: [] };
}

export const mqttReducer = (
    state: MqttPacketState = initialState(),
    action: AnyAction
): MqttPacketState => {
    switch (action.type) {
        case MqttActionTypes.NEW_PACKET:
            const packet = action.payload as MqttMessage;
            const filtered = state.messages.filter((item) => (item.DeviceID !== packet.DeviceID)).concat(packet);
            localStorage.setItem(MESSAGES_KEY, JSON.stringify(filtered))
            return { messages: filtered }
        default:
            return state
    }
}