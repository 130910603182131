import { Account } from "../../interfaces/account";
import GraphQLBaseResult from "../gql-base.dto";

// @ENUM of allowed roles of a user
export enum ROLES {
    SUPER_ADMIN = 'SUPER_ADMIN',
    ADMIN = 'ADMIN',
    STORE = 'STORE',
    CARRIER = 'CARRIER',
    SUPPORT = 'SUPPORT',
    USER = 'USER',
}

// @OBJECT for fetching GQL Login mutation result
export interface VerifyResultDTO extends GraphQLBaseResult {
    token: string;
    refresh: string;
    expire_at: Date;
}

// @OBJECT for transfear data to login GQL mutation
export interface LoginInputDTO {
    email: string;
    role: ROLES;
}

// @OBJECT for transfear data to verify GQL mutation
export interface VerifyInputDTO {
    email: string
    role: ROLES
    code: string
}

// @OBJECT for fetching account information from account GQL query
export interface AccountResultDTO extends GraphQLBaseResult {
    user: Account
}