import { client } from "../client";
import { GQL_CREATE_DEVICE, GQL_DEVICE, GQL_DEVICES, GQL_REMOVE_DEVICE, GQL_UPDATE_DEVICE } from "../data";
import GraphQLBaseResult from "../gql-base.dto";
import { CreateUpdateDeviceDTO, CreateUpdateDeviceResultDTO, DeviceResultDTO, DevicesResultDTO } from "./dto";

export async function devicesGQL(): Promise<DevicesResultDTO> {
    return new Promise(async (resolve) => {
        try {
            const response = await client.query({
                query: GQL_DEVICES
            });
            if (response.data) {
                resolve(response.data.devices as DevicesResultDTO);
            }
        } catch (error) {
            console.log('ERROR: ', error);
        }
    });
}

export async function deviceGQL(id: string): Promise<DeviceResultDTO> {
    return new Promise(async (resolve) => {
        try {
            const response = await client.query({
                query: GQL_DEVICE,
                variables: { id: id }
            });
            if (response.data) {
                resolve(response.data.device as DevicesResultDTO);
            }
        } catch (error) {
            console.log('ERROR: ', error);
        }
    });
}

export async function updateDeviceGQL(id: string, input: CreateUpdateDeviceDTO): Promise<CreateUpdateDeviceResultDTO> {
    console.log('id :', id, ' input : ', input)
    return new Promise(async (resolve) => {
        try {
            const response = await client.mutate({
                mutation: GQL_UPDATE_DEVICE,
                variables: { id: id, input: input },
            });
            if (response.data) {
                resolve(response.data.updateDevice as CreateUpdateDeviceResultDTO);
            }
        } catch (error) {
            console.log('ERROR: ', error);
        }
    });
}

export async function createDeviceGQL(input: CreateUpdateDeviceDTO): Promise<CreateUpdateDeviceResultDTO> {
    return new Promise(async (resolve) => {
        try {
            const response = await client.mutate({
                mutation: GQL_CREATE_DEVICE,
                variables: { input: input },
            });
            if (response.data) {
                resolve(response.data.createDevice as CreateUpdateDeviceResultDTO);
            }
        } catch (error) {
            console.log('ERROR: ', error);
        }
    });
}

export async function removeDeviceGQL(id: string): Promise<GraphQLBaseResult> {
    return new Promise(async (resolve) => {
        try {
            const response = await client.mutate({
                mutation: GQL_REMOVE_DEVICE,
                variables: { id: id },
            });
            if (response.data) {
                resolve(response.data.removeDevice as GraphQLBaseResult);
            }
        } catch (error) {
            console.log('ERROR: ', error);
        }
    });
}