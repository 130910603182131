import React, { createContext, useState } from 'react';
import Loading from '../components/Loading';

interface LoadingContextProps {
    loading: boolean,
    setLoading: Function
}

interface LoadingProps {
    children: React.ReactNode
}

export const LoadingProviderContext = createContext<LoadingContextProps>({
    loading: false,
    setLoading: () => null
})

export function LoadingProvider(props: LoadingProps): JSX.Element {
    const [loading, setLoading] = useState<boolean>(false);

    return (
        <LoadingProviderContext.Provider value={{ loading, setLoading }}>
            {props.children}
            <Loading show={loading} />
        </LoadingProviderContext.Provider>
    )
}