import { useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import DeviceItem, { DeviceItemActions } from '../components/Device/DeviceItem';
import { CurrentDeviceProviderContext } from '../contexts/current-device.context';
import { devicesGQL } from '../graphql/device/handlers';
import { accountGQL } from '../graphql/user/handlers';
import { Device } from '../interfaces/device';
import { selectAuthorization } from '../store/authorization';
import { selectDevices } from '../store/devices';
import { storeDevices } from '../store/devices/action';
import { mqttConnect } from '../store/mqtt/action';
import { ACCOUNT_KEY } from '../types';

export default function DashboardPage() {
    const authorization = useSelector(selectAuthorization);
    const devicesState = useSelector(selectDevices);
    const dispatch = useDispatch()

    // instance of browser history for redirection to device home.
    const history = useHistory();
    // current device context state
    const { setCurrentDevice } = useContext(CurrentDeviceProviderContext);

    useEffect(() => {
        if (authorization.expire_at > new Date() && authorization.token !== '') {
            (async () => {
                const result = await devicesGQL();
                if (result) {
                    if (result.success) {
                        dispatch(storeDevices({ devices: result.devices }));
                    }
                }
                const account = await accountGQL()
                if (account) {
                    localStorage.setItem(ACCOUNT_KEY, JSON.stringify(account.user));
                    mqttConnect(dispatch);
                }
            })();
        }
    }, [authorization, dispatch]);

    const deviceAction = (device: Device, action: DeviceItemActions): void => {
        if (action === 'MORE') {
            setCurrentDevice(device);
            history.push('/device')
        }
    }

    return (
        <>
            <div className="grid py-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-3">
                {
                    devicesState.devices.filter((device: Device) => (device.dashboard)).map((device, index) => (
                        <DeviceItem key={index} device={device} dashboard={true} onActionClick={deviceAction} />
                    ))
                }
            </div>
        </>
    )
}