import { AnyAction } from "redux";
import { RootState } from "..";
import { THEME_KEY } from "../../types";

export type Theme = 'dark' | 'light'

// declear redux action types name for theme
export enum ThemeActionTypes {
    SET_THEME = '@@theme/SET_THEME'
}

// declear theme state selector function
export const selectTheme = (rootState: RootState) =>
    rootState.theme;

// function that returns initial state based on local storage
export const themeInitialState = (): Theme => {
    const data = localStorage.getItem(THEME_KEY);
    if (data) {
        return data as Theme
    }
    return 'dark'
}


export const themeReducer = (
    state: Theme = themeInitialState(),
    action: AnyAction
): Theme => {
    switch (action.type) {
        case ThemeActionTypes.SET_THEME:
            localStorage.setItem(THEME_KEY, action.payload as Theme)
            return action.payload as Theme
        default:
            return state
    }
}