import React, { createContext } from 'react';
import useStorage from '../hooks/storage.hook';
import { Device } from '../interfaces/device';
import { CURRENT_DEVICE_KEY } from '../types';

export interface Props {
    currentDevice: Device | undefined
    setCurrentDevice: Function
}

export interface CurrentDeviceProviderProps {
    children: React.ReactNode
}

export const CurrentDeviceProviderContext = createContext<Props>({
    currentDevice: undefined,
    setCurrentDevice: (device: Device) => null
})

export function CurrentDeviceProvider(props: CurrentDeviceProviderProps): JSX.Element {
    const [currentDevice, setCurrentDevice] = useStorage<Device | undefined>(CURRENT_DEVICE_KEY, undefined);

    return (
        <CurrentDeviceProviderContext.Provider value={{ currentDevice, setCurrentDevice }}>
            {props.children}
        </CurrentDeviceProviderContext.Provider>
    )
}