import React, { RefObject, useContext } from 'react';
import Highcharts, { Chart, PointOptionsObject } from 'highcharts/highstock';
import HC_more from "highcharts/highcharts-more";
import HighchartsReact from 'highcharts-react-official';

import { Device } from '../../interfaces/device';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { CurrentDeviceProviderContext } from '../../contexts/current-device.context';
import { useRef } from 'react';
import { setInterval } from 'timers';
import moment from 'moment-timezone';

HC_more(Highcharts);

interface Props {
    device: Device;
    temperatures?: Array<PointOptionsObject>;
}

interface TempData {
    array: Array<PointOptionsObject>;
    locked: boolean;
}

let tempData: TempData = {
    array: [],
    locked: false
}

interface ref {
    chart: Chart;
    container: RefObject<HTMLDivElement>;
}

export default function TemperatureChart(props: Props) {
    const chartRef = useRef<ref>(null);
    // current device state
    const { currentDevice } = useContext(CurrentDeviceProviderContext);

    const options: Highcharts.Options = {
        chart: {
            type: 'line',
            zoomType: 'x',
            backgroundColor: '',
            events: {
                load: props.temperatures ? (function name() { }) : (function (this: Chart, event: Event) {
                    setInterval(() => {
                        if (this.series) {
                            if (this.series.length > 0) {
                                var series = this.series[0];
                                tempData.array.forEach((point) => {
                                    series.addPoint([point.x, point.y], true, series.data.length > 300, true);
                                })
                                tempData.array = [];
                            }
                        }
                    }, 2000);
                })
            }
        },
        title: {
            text: '',
        },
        xAxis: {
            title: { text: 'نمودار دما بر حسب زمان' },
            type: 'datetime',
            labels: {
                formatter: (function () {
                    if(this.chart.series[0].data.length > this.pos){
                        const t = this.chart.series[0].data[this.pos].options.x ? this.chart.series[0].data[this.pos].options.x : undefined
                        return moment(t).local().toDate().toLocaleDateString('fa-IR').toString()
                    }
                    return '';
                })
            }
        },
        time: {
            timezone: 'fa-IR'
        },
        legend: {
            enabled: false
        },
        scrollbar: {
            enabled: false
        },
        navigator: {
            enabled: false
        },
        rangeSelector: {
            enabled: false,
        },
        plotOptions: {
            area: {
                fillColor: {
                    linearGradient: {
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1
                    },
                    stops: [
                        [0, 'rgba(59, 130, 246, 0.3)'],
                        [1, 'rgba(59, 130, 246, 0.05)']
                    ]
                },
                marker: {
                    radius: 2
                },
                lineWidth: 1,
                states: {
                    hover: {
                        lineWidth: 2
                    }
                },
                threshold: null
            }
        },
        yAxis: {
            title: { text: 'دما' },
            plotLines: [{
                id: 'limit-max',
                width: 2,
                color: 'rgba(254, 54, 0, 1.0)',
                dashStyle: 'ShortDot',
                value: currentDevice ? currentDevice.temperature.max : 0.0,
                zIndex: 0,
                label: {
                    useHTML: true,
                    text: 'حداکثر مجاز'
                }
            }, {
                id: 'limit-min',
                color: 'rgba(254, 54, 0, 1.0)',
                dashStyle: 'ShortDot',
                width: 2,
                value: currentDevice ? currentDevice.temperature.min : 0.0,
                zIndex: 0,
                label: {
                    useHTML: true,
                    text: 'حداقل مجاز',
                }
            }]
        },
        tooltip: {
            split: false,
            useHTML: true,
            borderColor: 'rgba(0,0,0,0)',
            formatter: function () {
                const date = moment(this.key).local().toDate();
                return '<div class="font-bold text-md">' + date.toLocaleDateString('fa-IR') + ' ' + date.toLocaleTimeString('fa-IR') + '</div><div><span class="font-bold">' + this.y + '</span><span class="opacity-50 font-bold text-sm"> °C</span></div>';
            }
        },
        series: [
            {
                name: '',
                type: 'area',
                tooltip: {
                    valueDecimals: 2,
                    valueSuffix: ' °C'
                },
                data: props.temperatures ? props.temperatures : (function () {
                    if (currentDevice) {
                        const data = localStorage.getItem(`${currentDevice.id}_temperature`);
                        if (data) {
                            return JSON.parse(data) as Array<PointOptionsObject>;
                        }
                        return [];
                    }
                    return [];
                }())
            }
        ]
    };

    // read redux mqtt state anf filter to get only current device messages
    useSelector((state: RootState) => {
        const messages = currentDevice ? state.mqtt.messages.filter((item) => (item.DeviceID === currentDevice.id)) : [];
        const lastMessage = messages[messages.length - 1];
        if (lastMessage) {
            const point: PointOptionsObject = {
                x: lastMessage.Date,
                y: lastMessage.temperature.value_digit,
                id: lastMessage.id
            }
            tempData.array = tempData.array.filter((item) => (item.id !== point.id))
            tempData.array.push(point);
        }
    });

    return (
        <div className="hover:bg-white dark:hover:bg-black">
            <HighchartsReact
                constructorType={"stockChart"}
                highcharts={Highcharts}
                options={options}
                ref={chartRef} />
        </div>
    )
}
