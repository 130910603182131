import React, { createContext, useEffect, useState } from 'react';

export interface Props {
    show: boolean,
    setShow: Function
}

export interface DrawerProviderProps {
    children: React.ReactNode
}

export const initialState: string = 'light';

export const DrawerProviderContext = createContext<Props>({
    show: false,
    setShow: (args: boolean) => null
})

export function DrawerProvider(props: DrawerProviderProps): JSX.Element {
    const [show, setShow] = useState<boolean>(false);

    useEffect(() => {
        if (show) {
            document.documentElement.classList.add('modal-open');
        } else {
            document.documentElement.classList.remove('modal-open')
        }
    });

    return (
        <DrawerProviderContext.Provider value={{ show, setShow }}>
            {props.children}
        </DrawerProviderContext.Provider>
    )
}