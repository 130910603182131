import { ClockIcon } from "@heroicons/react/outline";
import TimeAgo from 'javascript-time-ago';
import React from "react";
import { ArrowDownShort, ArrowUpShort, Check, Droplet, InfoCircle, PencilFill, PinAngle, PinFill, ThermometerHalf, Trash } from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import { Device } from "../../interfaces/device";
import { RootState } from "../../store";
import { MqttMessage, MqttMessageSensore } from "../../types/mqtt";

export type DeviceItemActions = 'DELETE' | 'EDIT' | 'INFO' | 'DASHBOARD' | 'MORE';

interface Props {
    device: Device;
    dashboard?: boolean;
    onActionClick?: (device: Device, action: DeviceItemActions) => void;
}

export default function DeviceItem(props: Props) {
    // time ago utility instance for determine last received packet time
    const timeAgo = new TimeAgo('fa-IR');

    // read redux mqtt state anf filter to get only current device messages
    const message: MqttMessage | undefined = useSelector((state: RootState) => {
        const messages = state.mqtt.messages.filter((item) => (item.DeviceID === props.device.id));
        return messages[messages.length - 1];
    });

    // return data packet UP, Down or checkmark for present sensor value status
    const packetIcon = (data: MqttMessageSensore): React.ReactNode => {
        switch (data.type) {
            case 'humidity':
                if (data.value_digit < props.device.humidity.min) {
                    return <ArrowDownShort size={24} className="text-red-500" />
                }
                if (data.value_digit > props.device.humidity.max) {
                    return <ArrowUpShort size={24} className="text-red-500" />
                }
                return <Check size={20} className="text-green-500 self-center" />
            case 'temperature':
                if (data.value_digit < props.device.temperature.min) {
                    return <ArrowDownShort size={24} className="text-red-500" />
                }
                if (data.value_digit > props.device.temperature.max) {
                    return <ArrowUpShort size={24} className="text-red-500" />
                }
                return <Check size={20} className="text-green-500 self-center" />
        }
    }

    // return green or red color based on packet sensor value
    const packetColor = (data: MqttMessageSensore): string => {
        switch (data.type) {
            case 'humidity':
                if (data.value_digit < props.device.humidity.min || data.value_digit > props.device.humidity.max) {
                    return 'text-red-500';
                }
                return 'text-green-500';
            case 'temperature':
                if (data.value_digit < props.device.temperature.min || data.value_digit > props.device.temperature.max) {
                    return 'text-red-500';
                }
                return 'text-green-500';
        }
    }

    const onSelect = (event: React.MouseEvent) => {
        if (props.onActionClick && props.dashboard) props.onActionClick(props.device, 'MORE');
    }

    return (
        <div className={`bg-gray-100 hover:bg-white dark:bg-gray-900 rounded-2xl p-2 cursor-pointer hover:shadow-lg hover:border-gray-100 dark:hover:border-gray-900 dark:hover:bg-gray-700 border border-transparent ${props.dashboard ? '' : 'mt-3'}`}
            onClick={onSelect}>
            <div className={`font-medium text-right pr-2 text-blue-500 dark:text-gray-300 ${props.dashboard ? 'text-sm' : ''}`}>{props.device.display_name}</div>
            <div className="flex space-x-2 justify-between pt-2">
                <div>
                    <div className="text-center text-sm flex flex-row-reverse justify-center items-center opacity-50">
                        <ThermometerHalf size={18} className="" />
                        <div className="pr-1">دما</div>
                    </div>
                    <div className="flex justify-center items-center">
                        {message ?
                            packetIcon(message.temperature) : null
                        }
                        <div className={`text-xl font-bold font-sans ${message ? packetColor(message.temperature) : ''}`}>{message ? message.temperature.value : '--'}</div>
                        <div className="text-sm pl-1 font-bold opacity-50 self-end">{message ? message.temperature.unit : '--'}</div>
                    </div>
                </div>
                <div>
                    <div className="text-center text-sm flex flex-row-reverse justify-center items-center opacity-50">
                        <Droplet size={18} className="" />
                        <div className="pr-1">رطوبت</div>
                    </div>
                    <div className="flex justify-center items-center">
                        {message ?
                            packetIcon(message.humidity) : null
                        }
                        <div className={`text-xl font-bold font-sans ${message ? packetColor(message.humidity) : ''}`}>{message ? message.humidity.value : '--'}</div>
                        <div className="text-sm pl-1 font-bold opacity-50 self-end">
                            {
                                message ? message.humidity.unit : '--'
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="text-center flex flex-row-reverse py-3 items-center justify-center">
                <ClockIcon className="h-4 w-4 text-gray-500 dark:text-gray-100  opacity-30" />
                <div className="text-xs text-gray-800 dark:text-gray-100 pr-1 rtl opacity-50">آخرین دریافت:</div>
                <div className="text-xs rtl font-bold pr-1 opacity-80">
                    {
                        message ? timeAgo.format(message.Date) :
                            props.device.last_received ? timeAgo.format(props.device.last_received) : '--:--'
                    }
                </div>
            </div>
            {
                props.dashboard ? null :
                    <div className="flex flex-row-reverse justify-between pt-2">
                        <div
                            className={`${props.device.dashboard ? 'text-red-500' : 'text-green-500'} w-7 h-7 hover:bg-gray-100 dark:hover:bg-gray-900 font-medium cursor-pointer rounded-full flex flex-row-reverse items-center justify-center`}
                            onClick={() => {
                                if (props.onActionClick) {
                                    props.onActionClick(props.device, 'DASHBOARD')
                                }
                            }}>
                            {
                                props.device.dashboard ? <PinAngle size={18} /> : <PinFill size={18} />
                            }
                        </div>
                        <div className="flex-grow" />
                        <div
                            className="p-1 w-7 h-7 ml-2 bg-gray-200 dark:bg-gray-700 dark:text-gray-200 hover:text-white cursor-pointer hover:bg-blue-500 dark:hover:bg-blue-600 dark:hover:text-white rounded-full flex flex-row-reverse items-center justify-center"
                            onClick={() => {
                                if (props.onActionClick) {
                                    props.onActionClick(props.device, 'INFO')
                                }
                            }}>
                            <InfoCircle size={18} />
                        </div>
                        <div
                            className="p-2 w-7 h-7 ml-2 bg-gray-200 dark:bg-gray-700 dark:text-gray-200 hover:text-white cursor-pointer hover:bg-blue-500 dark:hover:bg-blue-600 dark:hover:text-white rounded-full flex flex-row-reverse items-center justify-center"
                            onClick={() => {
                                if (props.onActionClick) {
                                    props.onActionClick(props.device, 'EDIT')
                                }
                            }}>
                            <PencilFill size={18} />
                        </div>
                        <div
                            className="p-1 w-7 h-7 bg-gray-200 dark:bg-gray-700 text-red-500 hover:text-white cursor-pointer hover:bg-red-500 dark:hover:bg-red-500 dark:hover:text-white rounded-full flex flex-row-reverse items-center justify-center"
                            onClick={() => {
                                if (props.onActionClick) {
                                    props.onActionClick(props.device, 'DELETE')
                                }
                            }}>
                            <Trash size={16} />
                        </div>
                    </div>
            }
        </div>
    )
}