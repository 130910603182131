import gql from "graphql-tag";

export const GQL_LOGIN = gql`
mutation login($input: UserLoginInput!){
    login(input: $input){
        success
        message
        code
    }
}`;

export const GQL_VERIFY = gql`
mutation verify($input: UserVerifyInput!){
    verify(input: $input){
        success,
        message,
        code,
        token,
        refresh,
        expire_at,
    }
}`;

export const GQL_ACCOUNT = gql`
query account{
    account{
        success
        message
        code
        user {
            id
            email
            created_at
            updated_at
            name
        }
    }
}`;

export const GQL_DEVICES = gql`
query devices{
    devices {
    success
    message
    code
    devices {
      id
      display_name
      dashboard
      account_id
      last_received
      updated_at
      created_at
      secret
      temperature {
        enabled
        field_name
        min
        max
      }
      humidity {
        enabled
        field_name
        min
        max
      }
    }
  }
}`;

export const GQL_DEVICE = gql`
query devices($id: String!){
    device(id: $id) {
    success
    message
    code
    device {
      id
      display_name
      dashboard
      account_id
      last_received
      updated_at
      created_at
      secret
      temperature {
        enabled
        field_name
        min
        max
      }
      humidity {
        enabled
        field_name
        min
        max
      }
    }
  }
}`;

export const GQL_UPDATE_DEVICE = gql`
mutation updateDevice($id: String!, $input: CreateUpdateDeviceInputDTO!){
  updateDevice(
      id: $id
      input: $input
    ){
      success
      message
      code
      device {
        id
        display_name
        dashboard
        account_id
        last_received
        updated_at
        created_at
        secret
        temperature {
          min
          max
          field_name
          enabled
        }
        humidity {
          min
          max
          field_name
          enabled
        }
      }
    }
}`;

export const GQL_REMOVE_DEVICE = gql`
mutation removeDevice($id: String!){
  removeDevice( id: $id ){
      success
      message
      code
    }
}`;

export const GQL_CREATE_DEVICE = gql`
mutation createDevice($input: CreateUpdateDeviceInputDTO!){
  createDevice(input: $input){
      success
      message
      code
      device {
        id
        display_name
        dashboard
        account_id
        last_received
        updated_at
        created_at
        secret
        temperature {
          min
          max
          field_name
          enabled
        }
        humidity {
          min
          max
          field_name
          enabled
        }
      }
    }
}`;

export const GQL_PACKETS = gql`
query packets($input: PacketsInputDTO!){
  packets(input: $input) {
      success
      message
      code
      size
      temperatures {
        created_at
        value
        out_of_range
        unit
        type
        device_id
      }
      humidities {
        created_at
        value
        out_of_range
        unit
        type
        device_id
      }
    }
  }
`;

export const GQL_REPORTS = gql`
query reports($input: ReportsInputDTO!){
  reports(input: $input) {
      success
      message
      code
      size
      reports{
        id
        created_at
        updated_at
        device_id
        min_value
        max_value
        target
        closed
        packets
      }
    }
  }
`;