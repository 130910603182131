import React from "react"

export type ChartRangeSelector = 'REALTIME' | 'WEEKLY' | 'MONTHLY' | 'REPORT';

interface Props {
    selected: ChartRangeSelector;
    setSelected: (selected: ChartRangeSelector) => void;
}

export default function RangeSelector(props: Props) {
    return (
        <div className={`no-print overflow-hidden p-2 flex items-center justify-center font-bold`}>
            <div className={`text-sm flex rounded-full overflow-hidden shadow-md`}>
                <div className={`px-4 py-2 ${props.selected === 'REPORT' ? 'bg-blue-500 text-white' : 'bg-gray-200 dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-black'} cursor-pointer rtl transition-all`}
                    onClick={() => { props.setSelected('REPORT') }}>
                    گزارشات
                </div>
                <div className={`px-4 py-2  ${props.selected === 'MONTHLY' ? 'bg-blue-500 text-white' : 'bg-gray-200 dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-black'} cursor-pointer rtl transition-all`}
                    onClick={() => { props.setSelected('MONTHLY') }}>
                    ماهانه
                </div>
                <div className={`px-4 py-2 ${props.selected === 'WEEKLY' ? 'bg-blue-500 text-white' : 'bg-gray-200 dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-black'} cursor-pointer rtl transition-all`}
                    onClick={() => { props.setSelected('WEEKLY') }}>
                    هفتگی
                </div>
                <div className={`px-4 py-2 ${props.selected === 'REALTIME' ? 'bg-blue-500 text-white' : 'bg-gray-200 dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-black'} cursor-pointer rtl transition-all`}
                    onClick={() => { props.setSelected('REALTIME') }}>
                    آنلاین
                </div>
            </div>
        </div>
    )
}