import React, { useEffect, useState } from "react";

interface LoadingProps {
    show: boolean
}

export default function Loading(props: LoadingProps) {
    const [hide, setHide] = useState<boolean>(true);

    useEffect(() => {
        if (props.show) {
            setTimeout(() => {
                setHide(false)
            }, 300)
            document.documentElement.classList.add('no-scroll');
        } else {
            setTimeout(() => {
                setHide(true)
            }, 300)
            document.documentElement.classList.remove('no-scroll');
        }
    });

    return (
        <>
            {
                props.show ?
                    <div className={`loading z-40 flex justify-center items-center fixed inset-0 blur transition-all ${hide ? 'opacity-0' : 'opacity-100'}`}>
                        <div className="loader ease-linear rounded-full border-t-4 border-4 h-8 w-8"></div>
                    </div>
                    : null
            }
        </>
    )
}