import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectTheme } from '../../store/theme';
import { setTheme } from '../../store/theme/action';


export default function ThemeToggle() {
    const theme = useSelector(selectTheme)
    const dispatch = useDispatch();

    const themeToggler = () => {
        if (theme === 'light') {
            dispatch(setTheme('dark'));
            document.documentElement.classList.add('dark');
        } else {
            dispatch(setTheme('light'));
            document.documentElement.classList.remove('dark')
        }
    };

    useEffect(() => {
        if (theme === 'dark') {
            document.documentElement.classList.add('dark');
        } else {
            document.documentElement.classList.remove('dark')
        }
    }, [theme]);

    return (
        <div className="ml-2">
            <label className="switch">
                <input type="checkbox"
                    defaultChecked={
                        theme === 'light'
                            ? false
                            : true
                    }
                    onClick={
                        themeToggler
                    } />
                <span className="slider rounded-full bg-gray-100 dark:bg-gray-700 transition-all">
                </span>
            </label>
        </div>

    )
}