import mqtt from 'mqtt';

import { Account } from '../../interfaces/account';
import { AuthorizationInfo } from '../../interfaces/auth';
import { ACCOUNT_KEY, AUTH_TOKENS_KEY } from '../../types';
import { MqttMessage, MqttMessageSensore } from '../../types/mqtt';
import { MqttActionTypes } from '.';
import { Dispatch } from 'redux';
import * as uuid from 'uuid';
import { PointOptionsObject } from 'highcharts';

const MqttOptions: mqtt.IClientOptions = {
    host: 'mqtt://ws.hadrontec.ir/mqtt',
    keepalive: 30,
    clientId: '',
    protocol: 'wss',
    protocolVersion: 4,
    protocolId: 'MQTT',
    clean: true,
    reconnectPeriod: 5000,
    connectTimeout: 30 * 1000,
    resubscribe: true,
    rejectUnauthorized: false
}

let client: mqtt.Client;

export const mqttDisconnect = () => {
    console.log('DISCONNECTING MQTT');
    MqttOptions.clientId = '';
    MqttOptions.username = '';
    MqttOptions.password = '';

    if (client) {
        if (client.connected) {
            client.end();
        }
    }
}

export const mqttConnect = (dispatch: Dispatch) => {
    const authData = localStorage.getItem(AUTH_TOKENS_KEY);
    const accountData = localStorage.getItem(ACCOUNT_KEY);
    if (authData && accountData) {
        const auth: AuthorizationInfo = JSON.parse(authData) as AuthorizationInfo;
        const account: Account = JSON.parse(accountData) as Account;

        if (auth.token !== '') {
            MqttOptions.clientId = `superuser@${account.id}`;
            MqttOptions.username = `superuser@${account.id}`;
            MqttOptions.password = auth.token.trim();

            client = mqtt.connect(MqttOptions.host, MqttOptions);

            client.on('error', function (err) {
                console.log('ERROR -> ', err)
                client.end()
            });

            client.on('connect', function () {
                client.subscribe(`HadronCloud/${account.id}/#`, { qos: 2 });
            });

            client.on('message', function (topic, message, packet) {
                let split = topic.split("/");
                let deviceID = split[split.length - 1];
                if (deviceID !== 'undefined' && deviceID !== '') {
                    let packet: MqttMessage = JSON.parse(message.toString());
                    packet.Date = Date.now();
                    packet.id = uuid.v4();
                    packet.DeviceID = deviceID;
                    packet.sensors.forEach((item, index) => {
                        packet.sensors[index].value_digit = parseFloat(item.value.toString());
                        if (item.type === 'temperature') {
                            packet.temperature = { ...packet.sensors[index], date: packet.Date };
                        } else if (item.type === 'humidity') {
                            packet.humidity = { ...packet.sensors[index], date: packet.Date };
                        }
                    });
                    _storePacket(packet);
                    dispatch({ type: MqttActionTypes.NEW_PACKET, payload: packet });
                }
            });
        }
    }
}

function _storePacket(packet: MqttMessage) {
    _storeParam(packet.temperature, `${packet.DeviceID}_temperature`, 'temperature', packet.id);
    _storeParam(packet.humidity, `${packet.DeviceID}_humidity`, 'humidity', packet.id)
}

function _storeParam(param: MqttMessageSensore, key: string, taget: 'temperature' | 'humidity', id: string) {
    const data = localStorage.getItem(key);
    const point: PointOptionsObject = {
        x: param.date,
        y: param.value_digit,
        id: id
    }
    if (data) {
        let db = JSON.parse(data) as Array<PointOptionsObject>;
        db = db.slice(-360)
        db.push(point);
        db = db.filter((item, index) => {
            const _thing = JSON.stringify(item);
            return index === db.findIndex(obj => {
                return JSON.stringify(obj) === _thing;
            });
        });
        localStorage.setItem(key, JSON.stringify(db));
    } else {
        localStorage.setItem(key, JSON.stringify([point]));
    }
}