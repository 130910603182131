import { Dialog, Switch, Transition } from '@headlessui/react'
import { PencilAltIcon } from '@heroicons/react/outline';
import { Fragment, useEffect, useState } from 'react'
import { ArrowBarDown, ArrowBarUp, Droplet, ThermometerHalf } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';
import { CreateUpdateDeviceDTO } from '../../graphql/device/dto';
import { updateDeviceGQL } from '../../graphql/device/handlers';
import { Device, SenssoreConfig } from '../../interfaces/device';
import { Regexes } from '../../lib/regexes';
import { updateDevice } from '../../store/devices/action';

interface Props {
    show: boolean;
    device: Device;
    onClose: () => void;
    onSave: (device: Device) => void;
}

type FieldType = 'NAME' | 'PASSWORD' | 'TEMP_MIN' | 'TEMP_MAX' | 'HUM_MIN' | 'HUM_MAX' | 'DAHBOARD';

export default function EditDeviceDialog(props: Props) {

    // redux dispatch for updating devices list
    const dispatch = useDispatch();

    // input form errors state holder for validating inputs
    const [errors, setErrors] = useState<Array<FieldType>>([]);

    // taget device info DTO as state for holding changes of input
    const [deviceInfo, setDeviceInfo] = useState<CreateUpdateDeviceDTO>(props.device as CreateUpdateDeviceDTO);

    // fill target device info DTO with props device vlues
    useEffect(() => {
        setDeviceInfo({
            display_name: props.device.display_name,
            temperature: props.device.temperature,
            humidity: props.device.humidity,
            secret: props.device.secret,
            dashboard: props.device.dashboard
        });
    }, [props, setDeviceInfo])

    useEffect(() => {
        if (props.show) {
            document.documentElement.classList.add('no-scroll');
        } else {
            document.documentElement.classList.remove('no-scroll');
        }
    });

    // fill input field by component default device prop on user leave input empty 
    const handleOnBlure = (event: React.ChangeEvent<HTMLInputElement>, field: FieldType): void => {
        const value = event.currentTarget.value.trim();
        if (value === '') {
            switch (field) {
                case 'NAME':
                    event.currentTarget.value = props.device.display_name;
                    break;
                case 'PASSWORD':
                    event.currentTarget.value = props.device.secret;
                    break;
                case 'TEMP_MIN':
                    event.currentTarget.value = props.device.temperature.min.toString();
                    break;
                case 'TEMP_MAX':
                    event.currentTarget.value = props.device.temperature.max.toString();
                    break;
                case 'HUM_MIN':
                    event.currentTarget.value = props.device.humidity.min.toString();
                    break;
                case 'HUM_MAX':
                    event.currentTarget.value = props.device.humidity.max.toString();
                    break;
            }
            setErrors(errors.filter((item: FieldType) => (item !== field)));
        }
    }

    const editDevice = async () => {
        let result = await updateDeviceGQL(props.device.id, deviceInfo);
        if (result.success && result.device) {
            dispatch(updateDevice(result.device));
            props.onClose();
        }
    }

    return (
        <>
            <Transition
                show={props.show}
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0">
                <Dialog
                    as="div"
                    className="fixed inset-0 z-40 overflow-y-auto"
                    onClose={() => { }}>
                    <Dialog.Overlay className="fixed inset-0 bg-gray-700 opacity-90 blur" />
                    <div className="min-h-screen px-1 sm:px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0">
                            <Dialog.Overlay className="fixed inset-0" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="inline-block h-screen align-middle" aria-hidden="true" />
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95">
                            <div className="inline-block w-full max-w-md p-3 my-8 overflow-hidden text-left align-middle transition-all transform bg-white dark:bg-gray-900 shadow-xl rounded-2xl">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6">
                                    <div className="flex flex-row-reverse space-x-2 items-center text-blue-500 dark:text-blue-400">
                                        <PencilAltIcon className="w-6 h-6" />
                                        <div className={`pr-2 py-2 font-medium`}>ویرایش دستگاه</div>
                                    </div>
                                </Dialog.Title>
                                <div className="py-6">
                                    <div className="pb-6">
                                        <div className="pb-1 text-right rtl text-gray-400 dark:text-gray-500 font-medium">نام دستگاه:</div>
                                        <input
                                            type="text"
                                            className={`${errors.includes('NAME') ? 'border-red-500' : ''}
                                            border border-transparent p-2 bg-gray-100 dark:bg-gray-800 text-lg text-gray-700 dark:text-white rounded-xl w-full text-right rtl focus:bg-gray-200 focus:outline-none dark:focus:bg-gray-700`}
                                            defaultValue={props.device.display_name}
                                            placeholder={props.device.display_name}
                                            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                handleOnBlure(e, 'NAME');
                                            }}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                const value = e.currentTarget.value.trim();
                                                if (Regexes.DEVICE_NAME.test(value)) {
                                                    setDeviceInfo({ ...deviceInfo, display_name: value });
                                                    setErrors(errors.filter((item: FieldType) => (item !== 'NAME')))
                                                } else {
                                                    setErrors([...errors, 'NAME']);
                                                }
                                            }} />
                                    </div>
                                    <div className="pb-6">
                                        <div className="pb-1 text-right rtl font-medium text-gray-400 dark:text-gray-500">کلمه عبور سرویس MQTT:</div>
                                        <input
                                            type="text"
                                            className={`${errors.includes('PASSWORD') ? 'border-red-500' : ''}
                                            border border-transparent p-2 bg-gray-100 dark:bg-gray-800 text-lg text-gray-700 dark:text-white rounded-xl w-full focus:bg-gray-200 focus:outline-none dark:focus:bg-gray-700`}
                                            defaultValue={props.device.secret}
                                            placeholder={props.device.secret}
                                            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                handleOnBlure(e, 'PASSWORD');
                                            }}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                const value = e.currentTarget.value.trim();
                                                if (Regexes.DEVICE_PASSWORD.test(value)) {
                                                    setDeviceInfo({ ...deviceInfo, secret: value });
                                                    setErrors(errors.filter((item: FieldType) => (item !== 'PASSWORD')))
                                                } else {
                                                    setErrors([...errors, 'PASSWORD']);
                                                }
                                            }} />
                                    </div>
                                    <div className="pb-6 text-gray-500 dark:text-gray-300">
                                        <div className="flex flex-row-reverse font-medium text-gray-400 dark:text-gray-500">
                                            <ThermometerHalf size={20} className="text-blue-500" />
                                            <div className="pb-1 text-right rtl pr-2">تنظیمات سنسور دما</div>
                                        </div>
                                        <div className="flex space-x-2 items-center">
                                            <div className="text-blue-500">
                                                <div className="flex space-x-1 items-center justify-center pb-1">
                                                    <div className="text-center text-sm font-medium">MIN</div>
                                                    <ArrowBarUp size={20} />
                                                </div>
                                                <input
                                                    type="text"
                                                    className={`${errors.includes('TEMP_MIN') ? 'border-red-500' : ''} border border-transparent p-2 bg-gray-100 dark:bg-gray-800 text-lg text-gray-700 dark:text-white rounded-xl w-full focus:bg-gray-200 focus:outline-none dark:focus:bg-gray-700`}
                                                    defaultValue={props.device.temperature.min}
                                                    placeholder={props.device.temperature.min.toString()}
                                                    onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        handleOnBlure(e, 'TEMP_MIN');
                                                    }}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        const value = parseFloat(parseFloat(e.currentTarget.value.trim()).toFixed(2));
                                                        if (value) {
                                                            const temp: SenssoreConfig = { ...deviceInfo.temperature, min: value };
                                                            setDeviceInfo({ ...deviceInfo, temperature: temp });
                                                            setErrors(errors.filter((item: FieldType) => (item !== 'TEMP_MIN')))
                                                        } else {
                                                            setErrors([...errors, 'TEMP_MIN']);
                                                        }
                                                    }} />
                                            </div>
                                            <div className="text-blue-500">
                                                <div className="flex space-x-1 items-center justify-center pb-1">
                                                    <div className="text-center text-sm font-medium">MAX</div>
                                                    <ArrowBarDown size={20} />
                                                </div>
                                                <input
                                                    type="text"
                                                    className={`${errors.includes('TEMP_MAX') ? 'border-red-500' : ''} border border-transparent p-2 bg-gray-100 dark:bg-gray-800 text-lg text-gray-700 dark:text-white rounded-xl w-full focus:bg-gray-200 focus:outline-none dark:focus:bg-gray-700`}
                                                    defaultValue={props.device.temperature.max}
                                                    placeholder={props.device.temperature.max.toString()}
                                                    onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        handleOnBlure(e, 'TEMP_MAX');
                                                    }}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        const value = parseFloat(parseFloat(e.currentTarget.value.trim()).toFixed(2));
                                                        if (value) {
                                                            const temp: SenssoreConfig = { ...deviceInfo.temperature, max: value };
                                                            setDeviceInfo({ ...deviceInfo, temperature: temp });
                                                            setErrors(errors.filter((item: FieldType) => (item !== 'TEMP_MAX')))
                                                        } else {
                                                            setErrors([...errors, 'TEMP_MAX']);
                                                        }
                                                    }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pb-6 text-gray-500 dark:text-gray-300">
                                        <div className="flex flex-row-reverse font-medium text-gray-400 dark:text-gray-500">
                                            <Droplet size={20} className="text-blue-500" />
                                            <div className="pb-1 text-right rtl pr-2">تنظیمات سنسور رطوبت</div>
                                        </div>
                                        <div className="flex space-x-2 items-center">
                                            <div className="text-blue-500">
                                                <div className="flex space-x-1 items-center justify-center pb-1">
                                                    <div className="text-center text-sm font-medium">MIN</div>
                                                    <ArrowBarUp size={20} />
                                                </div>
                                                <input
                                                    type="text"
                                                    className={`${errors.includes('HUM_MIN') ? 'border-red-500' : ''} border border-transparent p-2 bg-gray-100 dark:bg-gray-800 text-lg text-gray-700 dark:text-white rounded-xl w-full focus:bg-gray-200 focus:outline-none dark:focus:bg-gray-700`}
                                                    defaultValue={props.device.humidity.min}
                                                    placeholder={props.device.humidity.min.toString()}
                                                    onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        handleOnBlure(e, 'HUM_MIN');
                                                    }}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        const value = parseFloat(parseFloat(e.currentTarget.value.trim()).toFixed(2));
                                                        if (value) {
                                                            const hum: SenssoreConfig = { ...deviceInfo.humidity, min: value };
                                                            setDeviceInfo({ ...deviceInfo, humidity: hum });
                                                            setErrors(errors.filter((item: FieldType) => (item !== 'HUM_MIN')))
                                                        } else {
                                                            setErrors([...errors, 'HUM_MIN']);
                                                        }
                                                    }} />
                                            </div>
                                            <div className="text-blue-500">
                                                <div className="flex space-x-1 items-center justify-center pb-1">
                                                    <div className="text-center text-sm font-medium">MAX</div>
                                                    <ArrowBarDown size={20} />
                                                </div>
                                                <input
                                                    type="text"
                                                    className={`${errors.includes('HUM_MAX') ? 'border-red-500' : ''} border border-transparent p-2 bg-gray-100 dark:bg-gray-800 text-lg text-gray-700 dark:text-white rounded-xl w-full focus:bg-gray-200 focus:outline-none dark:focus:bg-gray-700`}
                                                    defaultValue={props.device.humidity.max}
                                                    placeholder={props.device.humidity.max.toString()}
                                                    onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        handleOnBlure(e, 'HUM_MAX');
                                                    }}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        const value = parseFloat(parseFloat(e.currentTarget.value.trim()).toFixed(2));
                                                        if (value) {
                                                            const hum: SenssoreConfig = { ...deviceInfo.humidity, max: value };
                                                            setDeviceInfo({ ...deviceInfo, humidity: hum });
                                                            setErrors(errors.filter((item: FieldType) => (item !== 'HUM_MAX')))
                                                        } else {
                                                            setErrors([...errors, 'HUM_MAX']);
                                                        }
                                                    }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex justify-start space-x-2 items-center">
                                        <Switch
                                            checked={deviceInfo.dashboard}
                                            onChange={() => {
                                                setDeviceInfo({ ...deviceInfo, dashboard: !deviceInfo.dashboard })
                                            }}
                                            className={`${deviceInfo.dashboard ? 'bg-indigo-500' : 'bg-gray-200 dark:bg-gray-700'} relative inline-flex items-center h-8 rounded-full w-14 transition ease-in-out duration-200`}>
                                            <span className={`${deviceInfo.dashboard ? 'translate-x-7 bg-white' : 'translate-x-1 bg-gray-400'} inline-block w-6 h-6 transform rounded-full transition ease-in-out duration-200`} />
                                        </Switch>
                                        <div className="pl-2">نمایش در داشبورد</div>
                                    </div>
                                </div>
                                <div className="mt-4 flex space-x-2 justify-between">
                                    <button
                                        type="button"
                                        className={`${errors.length > 0 ? ' opacity-25 blur cursor-not-allowed' : ''} inline-flex justify-center px-4 py-2 border border-transparent rounded-xl focus:outline-none bg-blue-500 text-white hover:bg-blue-700 transition-all font-medium`}
                                        onClick={editDevice}>
                                        ثبت تغییرات
                                    </button>
                                    <button
                                        type="button"
                                        className="inline-flex justify-center px-4 py-2 border border-transparent rounded-xl focus:outline-none text-gray-500 dark:text-gray-400 hover:text-gray-800 dark:hover:text-white hover:bg-gray-100 dark:hover:bg-gray-800 transition-all"
                                        onClick={props.onClose}>
                                        انصراف
                                    </button>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}