import { AuthorizationInfo } from "../interfaces/auth";
import { Device } from "../interfaces/device";
import { combineReducers, createStore } from "redux";
import { authorizationReducer } from "./authorization";
import { devicesReducer } from "./devices";
import { themeReducer } from "./theme";
import { mqttReducer } from "./mqtt";

export interface ApplicationState {
    theme: string;
    loading: boolean;
    drawer: boolean;
    devices: Array<Device>;
    authorization: AuthorizationInfo;
}

export interface ApplicationAction {
    type: string;
    payload: any;
}

export type RootState = ReturnType<typeof rootReducer>;

export const rootReducer = combineReducers({
    authorization: authorizationReducer,
    devices: devicesReducer,
    theme: themeReducer,
    mqtt: mqttReducer
});

export const store = createStore(rootReducer)
