import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useEffect } from 'react'
import { Calendar, Day, DayValue, utils } from "@hassanmojab/react-modern-calendar-datepicker";
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import moment from 'moment-jalaali';

export type TargetDate = 'BEFORE' | 'AFTER';
interface Props {
    show: boolean;
    default?: string;
    minimum?: string;
    maximum?: string;
    target: TargetDate;
    onSelect: (day: DayValue, target: TargetDate) => void;
    onClose: () => void;
}

export default function DatePickerModal(props: Props) {

    useEffect(() => {
        if (props.show) {
            document.documentElement.classList.add('no-scroll');
        } else {
            document.documentElement.classList.remove('no-scroll');
        }
    });

    function parseDate(date?: string): DayValue | undefined {
        if (date) {
            const momentDate = moment(date);
            return {
                year: momentDate.jYear(),
                month: momentDate.jMonth() + 1,
                day: momentDate.jDate()
            } as DayValue;
        }
        return undefined;
    }

    return (
        <>
            <Transition
                show={props.show}
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0">
                <Dialog
                    as="div"
                    className="fixed inset-0 z-40 overflow-y-auto"
                    onClose={props.onClose}>
                    <Dialog.Overlay className="fixed inset-0 bg-gray-700 opacity-30 blur" />
                    <div className="min-h-screen  text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0">
                            <Dialog.Overlay className="fixed inset-0" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95">
                            <div className="inline-block overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                <div className="flex items-center justify-center">
                                    <Calendar
                                        value={props.default ? parseDate(props.default) : utils('fa').getToday()}
                                        onChange={(selected: DayValue) => {
                                            props.onSelect(selected, props.target)
                                        }}
                                        minimumDate={props.minimum && props.target !== 'AFTER' ? parseDate(props.minimum) as Day : undefined}
                                        maximumDate={props.maximum ? parseDate(props.maximum) as Day : utils('fa').getToday()}
                                        shouldHighlightWeekends
                                        locale="fa"
                                        colorPrimary="#6e60f9"
                                    />
                                </div>
                                <div className="mt-4 flex space-x-2">
                                    <button
                                        type="button"
                                        className="inline-flex justify-center px-4 py-2 border border-transparent rounded-xl font-medium focus:outline-none text-gray-600 hover:text-indigo-500 transition-all"
                                        onClick={props.onClose}>
                                        انصراف
                                    </button>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}