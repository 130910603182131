export const C_VERIFICATION_CODE_SENT_SUCCESS = 1000;
export const M_VERIFICATION_CODE_SENT_SUCCESS = 'کد فعال سازی با موفقیت ارسال گردید.';

export const C_VERIFICATION_CODE_RESENT_SUCCESS = 1001;
export const M_VERIFICATION_CODE_RESENT_SUCCESS = 'کد فعال سازی مجددا ارسال گردید.';

export const C_VERIFICATION_CODE_NOT_EXPIRED = 1101;
export const M_VERIFICATION_CODE_NOT_EXPIRED = 'بتازگی کد فعال سازی برای شما ارسال گردیده، لطفا چند دقیقه صبر کنید و دوباره تلاش نمایید و اگر کد تایید را در اختیار دارید آنرا وارد نمایید.';

export const C_INTERNAL_ERROR_ON_UPDATE_VERIFICATION_CODE = 1102;
export const M_INTERNAL_ERROR_ON_UPDATE_VERIFICATION_CODE = 'خطایی در ارسال کد فعال سازی رخ داده، لطفا بعدا امتحان کنید.';

export const C_INVALID_USER_ROLE = 1103;
export const M_INVALID_USER_ROLE = 'خطای سطح دسترسی، لطفا با پشتیبانی تماس بگیرید.';

export const C_INVALID_VERIFICATION = 1104;
export const M_INVALID_VERIFICATION = 'کد فعال سازی استفاده شده اشتباه است، لطفا مجددا تلاش کنید';

export const C_VERIFICATION_EXPIRED = 1105;
export const M_VERIFICATION_EXPIRED = 'زمان اعتبار کد فعال سازی به پایان رسیده و یا اشتباه است.';

export const C_VERIFICATION_SUCCESS = 1002;
export const M_VERIFICATION_SUCCESS = 'verification success';

export const C_JWE_TOKEN_ERR = 1105;
export const M_JWE_TOKEN_ERR = 'internal server error';

export const C_GET_USER_SUCCESS = 1002;
export const M_GET_USER_SUCCESS = 'user fetched success';

export const C_GET_USER_FAILED = 1106;
export const M_GET_USER_FAILED = 'user is not exist or locked';