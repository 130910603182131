import { client } from "../client";
import { GQL_REPORTS } from "../data";
import { ReportsInputDTO, ReportsOutputDTO } from "./dto";

export async function reportsGQL(input: ReportsInputDTO): Promise<ReportsOutputDTO> {
    return new Promise(async (resolve) => {
        try {
            const response = await client.query({
                query: GQL_REPORTS,
                variables: { input: input }
            });
            if (response.data) {
                resolve(response.data.reports as ReportsOutputDTO);
            }
        } catch (error) {
            console.log('ERROR: ', error);
        }
    });
}