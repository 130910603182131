import { AnyAction } from "redux";
import { RootState } from "..";
import { Device } from "../../interfaces/device";
import { DEVICES_KEY } from "../../types";

export interface DevicesState {
    devices: Array<Device>,
}
// declear redux action types name for devices actions
export enum DevicesActionTypes {
    STORE_DEVICES = '@@devices/STORE',
    REMOVE_DEVICES = '@@devices/REMOVE_DEVICES',
    REMOVE_DEVICE = '@@devices/REMOVE_DEVICE',
    UPDATE_DEVICE = '@@devices/UPDATE_DEVICE',
    CREATE_DEVICE = '@@devices/CREATE_DEVICE'
}

// declear devices state selector function
export const selectDevices = (rootState: RootState) =>
    rootState.devices;

// function that returns initial state based on local storage
export const devicesInitialState = (): DevicesState => {
    const data = localStorage.getItem(DEVICES_KEY);
    if (data) {
        const devices = JSON.parse(data) as Array<Device>;
        return { devices: devices }
    }
    return {
        devices: []
    }
}

export const devicesReducer = (
    state: DevicesState = devicesInitialState(),
    action: AnyAction
): DevicesState => {
    const data = localStorage.getItem(DEVICES_KEY);
    switch (action.type) {
        case DevicesActionTypes.STORE_DEVICES:
            const devices = action.payload as DevicesState
            localStorage.setItem(DEVICES_KEY, JSON.stringify(devices.devices))
            return action.payload as DevicesState
        case DevicesActionTypes.UPDATE_DEVICE:
            if (data) {
                const stored: Array<Device> = JSON.parse(data) as Array<Device>;
                const device: Device = action.payload as Device;
                stored.forEach((item, index) => {
                    if (item.id === device.id) {
                        stored[index] = device;
                    }
                });
                localStorage.setItem(DEVICES_KEY, JSON.stringify(stored));
                return ({ devices: stored });
            }
            return state;
        case DevicesActionTypes.REMOVE_DEVICE:
            if (data) {
                let stored: Array<Device> = JSON.parse(data) as Array<Device>;
                const id: string = action.payload as string;
                stored = stored.filter((item: Device) => (item.id !== id));
                localStorage.setItem(DEVICES_KEY, JSON.stringify(stored));
                return ({ devices: stored });
            }
            return state;
        case DevicesActionTypes.CREATE_DEVICE:
            if (data) {
                const newDevice: Device = action.payload as Device;
                let stored: Array<Device> = JSON.parse(data) as Array<Device>;
                stored.push(newDevice);
                localStorage.setItem(DEVICES_KEY, JSON.stringify(stored));
                return ({ devices: stored });
            }
            return state;
        case DevicesActionTypes.REMOVE_DEVICES:
            localStorage.setItem(DEVICES_KEY, JSON.stringify([]))
            return { devices: [] };
        default:
            return state
    }
}