import { Dialog, Transition } from '@headlessui/react'
import { TrashIcon } from '@heroicons/react/outline';
import { Fragment, useEffect } from 'react'
import { Device } from '../../interfaces/device';

interface Props {
    show: boolean;
    device: Device;
    onClose: () => void;
    onOKPress: (device: Device) => void;
}

export default function RemoveDeviceDialog(props: Props) {

    useEffect(() => {
        if (props.show) {
            document.documentElement.classList.add('no-scroll');
        } else {
            document.documentElement.classList.remove('no-scroll');
        }
    });

    return (
        <>
            <Transition
                show={props.show}
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0">
                <Dialog
                    as="div"
                    className="fixed inset-0 z-40 overflow-y-auto"
                    onClose={props.onClose}>
                    <Dialog.Overlay className="fixed inset-0 bg-gray-700 opacity-30 blur" />
                    <div className="min-h-screen px-1 sm:px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0">
                            <Dialog.Overlay className="fixed inset-0" />
                        </Transition.Child>
                        <span className="inline-block h-screen align-middle" aria-hidden="true" />
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95">
                            <div className="inline-block w-full max-w-md p-3 my-8 overflow-hidden text-left align-middle transition-all transform bg-white dark:bg-gray-900 shadow-xl rounded-2xl">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6">
                                    <div className="flex p-2 flex-row-reverse space-x-2 items-center text-red-500">
                                        <TrashIcon className="w-6 h-6" />
                                        <div className={`pr-1 font-medium`}>هشدار حذف دستگاه</div>
                                    </div>
                                </Dialog.Title>
                                <div className="px-4 rtl text-right">
                                    <div className="px-4 pb-6 text-justify text-gray-800 dark:text-white">در صورت حذف دستگاه، امکان بازیابی داده های دستگاه به هیج عنوان امکان پذید نمیباشد.</div>
                                    <div className="font-medium flex py-4">
                                        <div>آیا میخواهید</div>
                                        <div className="px-2 font-bold text-red-500">{props.device.display_name}</div>
                                        <div>را حذف کنید ؟</div>
                                    </div>
                                </div>
                                <div className="mt-4 flex space-x-2 justify-between">
                                    <button
                                        type="button"
                                        className="inline-flex justify-center px-4 py-2 border border-transparent rounded-xl focus:outline-none text-white bg-red-500 hover:bg-red-700 font-medium transition-all"
                                        onClick={() => {
                                            props.onOKPress(props.device);
                                        }}>
                                        بله، حذف شود
                                    </button>
                                    <button
                                        type="button"
                                        className="inline-flex justify-center px-4 py-2 border border-transparent rounded-xl focus:outline-none text-gray-500 dark:text-gray-400 hover:text-gray-800 dark:hover:text-white hover:bg-gray-100 dark:hover:bg-gray-800 transition-all"
                                        onClick={props.onClose}>
                                        انصراف
                                    </button>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}