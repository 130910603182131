import { useContext, useEffect, useState } from "react";
import { DayValue } from "@hassanmojab/react-modern-calendar-datepicker";
import moment from 'moment-jalaali';
import { Printer, SortDownAlt, SortUpAlt, X } from "react-bootstrap-icons";

import { CurrentDeviceProviderContext } from "../../contexts/current-device.context";
import { LoadingProviderContext } from "../../contexts/loading.context";
import { ReportItem, ReportsInputDTO, REPORT_TARGET } from "../../graphql/reports/dto";
import { reportsGQL } from "../../graphql/reports/handlers";
import { SORT } from "../../graphql/types";
import DatePickerModal, { TargetDate } from "../DatePickerModal";
import TargetSelectorModal, { ReportTargetSelectorItem } from "./TargetSelectorModal";
import ReportItemRow from "./ReportItemRow";

interface DaySelectorModalProp {
    target: TargetDate;
    show: boolean;
}
export default function DeviceReportList() {
    const [daySelectorProps, setDaySelectorProps] = useState<DaySelectorModalProp>({ target: 'AFTER', show: false });
    const { currentDevice } = useContext(CurrentDeviceProviderContext);
    const { setLoading } = useContext(LoadingProviderContext);
    let [reports, setReports] = useState<Array<ReportItem>>([])

    const [input, setInput] = useState<ReportsInputDTO>({
        device_id: '',
        after: undefined,
        before: undefined,
        target: REPORT_TARGET.BOTH,
        sort: SORT.DESC
    });
    const [showTargetModal, setShowTargetModal] = useState<boolean>(false);
    const [targetName, setTargetName] = useState<string>('دما و رطوبت');

    useEffect(() => {
        if (currentDevice && input.device_id === '') {
            setInput({ ...input, device_id: currentDevice.id })
        }
    }, [input, setInput, currentDevice]);

    const changeSort = () => {
        setInput({ ...input, sort: input.sort === SORT.ASC ? SORT.DESC : SORT.ASC });
        if (reports.length > 0) {
            fetchReports();
        }
    }

    const changeTarget = (target: ReportTargetSelectorItem): void => {
        setInput({ ...input, target: target.target });
        setTargetName(target.name);
        setShowTargetModal(false);
    }


    const fetchReports = async () => {
        setLoading(true);
        const result = await reportsGQL(input);
        if (result.success) {
            setReports(result.reports)
        }
        setLoading(false);
    }

    const selectedDay = (day: DayValue, target: TargetDate): void => {
        if (day) {
            const date = moment(`${day.year}/${day.month}/${day.day}`, 'jYYYY/jM/jD').format();
            switch (target) {
                case 'AFTER':
                    setInput({ ...input, after: date });
                    break;
                case 'BEFORE':
                    setInput({ ...input, before: date });
                    break;
            }
        }
        setDaySelectorProps({ ...daySelectorProps, show: false })
    }

    const printReport = () => {
        window.print();
    }

    return (
        <>
            <div className="p-2">
                <div className="no-print border-b border-gray-700 border-dashed py-4 flex items-center justify-end space-x-2">
                    <div className="flex items-center justify-center p-2 rounded-lg cursor-pointer text-gray-300 bg-blue-900 hover:bg-blue-500 hover:text-white"
                        onClick={changeSort}>
                        <div className="text-sm font-medium pr-1">
                            { input.sort === SORT.ASC ? 'سعودی' : 'نزولی'}
                        </div>
                        {
                            input.sort === SORT.ASC ? <SortDownAlt size={18} /> : <SortUpAlt size={20} />
                        }
                    </div>
                    <div className="flex-grow sm:flex space-x-8 justify-center items-center text-sm">
                        <div className="hidden sm:flex items-center">
                            {
                                input.before ? <X size={18} className="bg-blue-700 text-gray-300 rounded-full w-5 h-5 mr-1 flex items-center justify-center hover:bg-blue-500 cursor-pointer"
                                    onClick={() => {
                                        setInput({ ...input, before: undefined })
                                    }} /> : null
                            }
                            <div className="px-3 py-1 rounded-lg bg-gray-200 dark:bg-gray-800 hover:bg-blue-500 hover:text-white dark:hover:bg-blue-500 dark:hover:text-white font-bold mr-1 cursor-pointer"
                                onClick={() => {
                                    setDaySelectorProps({ target: 'BEFORE', show: true })
                                }}>{input.before ? moment(input.before).format('jYYYY / jM / jD') : '?'}
                            </div>
                            <div className="rtl font-medium">تا تاریخ :</div>
                        </div>
                        <div className="hidden sm:flex items-center">
                            {
                                input.after ? <X size={18} className="bg-blue-700 text-gray-300 rounded-full w-5 h-5 mr-1 flex items-center justify-center hover:bg-blue-500 cursor-pointer"
                                    onClick={() => {
                                        setInput({ ...input, after: undefined })
                                    }} /> : null
                            }

                            <div className="px-3 py-1 rounded-lg bg-gray-200 dark:bg-gray-800 hover:bg-blue-500 hover:text-white dark:hover:bg-blue-500 dark:hover:text-white font-bold mr-1 cursor-pointer"
                                onClick={() => {
                                    setDaySelectorProps({ target: 'AFTER', show: true })
                                }}>{input.after ? moment(input.after).format('jYYYY / jM / jD') : '?'}
                            </div>
                            <div className="rtl font-medium">از تاریخ :</div>
                        </div>
                    </div>
                    <div className="flex space-x-2 items-center text-sm">
                        <div className="rounded-lg bg-gray-200 dark:bg-gray-700 hover:text-white dark:hover:text-white hover:bg-blue-500 dark:hover:bg-blue-500 px-4 py-2 font-medium cursor-pointer"
                            onClick={() => {
                                setShowTargetModal(true)
                            }}>
                            {targetName}
                        </div>
                        <div className="rtl">سنسور :</div>
                    </div>
                </div>
                <div className="no-print flex sm:hidden space-x-8 justify-center items-center text-sm py-2">
                    <div className="sm:hidden items-center flex-grow">
                        <div className="rtl font-medium pb-2">تا تاریخ</div>
                        <div className="flex space-x-1 items-center">
                            {
                                input.before ? <X size={18} className="bg-blue-700 rounded-full w-5 h-5 flex items-center justify-center hover:bg-blue-500 cursor-pointer"
                                    onClick={() => {
                                        setInput({ ...input, before: undefined })
                                    }} /> : null
                            }
                            <div className="flex-grow px-3 py-1 rounded-md bg-gray-200 dark:bg-gray-800 hover:bg-blue-500 hover:text-white dark:hover:bg-blue-500 dark:hover:text-white font-bold mr-1 cursor-pointer"
                                onClick={() => {
                                    setDaySelectorProps({ target: 'BEFORE', show: true })
                                }}>{input.before ? moment(input.before).format('jYYYY / jM / jD') : '?'}
                            </div>
                        </div>
                    </div>
                    <div className="sm:hidden items-center justify-center flex-grow">
                        <div className="rtl font-medium pb-2">از تاریخ</div>
                        <div className="flex space-x-1 items-center">
                            {
                                input.after ? <X size={18} className="bg-blue-700 rounded-full w-5 h-5 flex items-center justify-center hover:bg-blue-500 cursor-pointer"
                                    onClick={() => {
                                        setInput({ ...input, after: undefined })
                                    }} /> : null
                            }
                            <div className="flex-grow px-3 py-1 rounded-md bg-gray-200 dark:bg-gray-800 hover:bg-blue-500 hover:text-white dark:hover:bg-blue-500 dark:hover:text-white font-bold mr-1 cursor-pointer"
                                onClick={() => {
                                    setDaySelectorProps({ target: 'AFTER', show: true })
                                }}>{input.after ? moment(input.after).format('jYYYY / jM / jD') : '?'}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="no-print flex items-center justify-center sm:justify-start py-2 space-x-2" >
                    {reports.length > 0 ?

                        <div className="flex items-center justify-center space-x-1 p-2 rounded-lg bg-gray-200 dark:bg-gray-700 hover:bg-blue-500 dark:hover:bg-blue-500 text-gray-500 dark:text-gray-400 hover:text-white dark:hover:text-white cursor-pointer"
                            onClick={printReport}>
                            <Printer size={18} />
                        </div> : null
                    }
                    <div className="p-2 rounded-lg bg-blue-700 hover:bg-blue-500 text-white cursor-pointer flex-grow sm:flex-none sm:px-6 text-center text-sm font-bold"
                        onClick={fetchReports}>
                        دریافت گزارش
                    </div>
                </div>
                <div className="pt-4 text-sm" id="reports_container">
                    {
                        reports.map((element: ReportItem, index: number) => (
                            <ReportItemRow data={element} index={index + 1} key={index} />
                        ))
                    }
                </div>
            </div>
            <TargetSelectorModal
                show={showTargetModal}
                onSelectedChanged={changeTarget}
                onClose={() => { setShowTargetModal(false) }} />
            <DatePickerModal
                show={daySelectorProps.show}
                target={daySelectorProps.target}
                minimum={input.after}
                maximum={input.before}
                default={daySelectorProps.target === 'AFTER' ? input.after : input.before}
                onClose={() => {
                    setDaySelectorProps({ ...daySelectorProps, show: false })
                }}
                onSelect={selectedDay} />
        </>
    )
}