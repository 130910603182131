import { useContext, useState } from "react"
import { Plus, ViewList } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { DrawerProviderContext } from "../../contexts/drawer.context";
import { CreateUpdateDeviceDTO } from "../../graphql/device/dto";
import { removeDeviceGQL, updateDeviceGQL } from "../../graphql/device/handlers";
import { Device } from "../../interfaces/device";
import { selectDevices } from "../../store/devices";
import { removeDevice, updateDevice } from "../../store/devices/action";
import DeviceInfoDialog from "../Device/DeviceInfoModal";
import DeviceItem, { DeviceItemActions } from "../Device/DeviceItem";
import EditDeviceDialog from "../Device/EditDeviceDialog";
import NewDeviceDialog from "../Device/NewDeviceDialog";
import RemoveDeviceDialog from "../Device/RemoveDeviceDialog";


interface DeviceDialogsState {
    show: boolean;
    device?: Device;
    mode: 'EDIT' | 'INFO' | 'NONE' | 'DELETE'
}

const initialState: DeviceDialogsState = {
    show: false,
    device: undefined,
    mode: 'NONE'
}

export default function Drawer() {
    // drawer visibility state from DrawerProvider context
    const { show } = useContext(DrawerProviderContext);

    // redux dispatch for updating device by actions
    const dispatch = useDispatch();

    // device current device dialog state
    const [dialogState, setDialogState] = useState<DeviceDialogsState>(initialState);

    // add device dialog state
    const [newDevice, setNewDevice] = useState<boolean>(false);

    const devicesState = useSelector(selectDevices);

    const onDeviceAction = async (device: Device, action: DeviceItemActions): Promise<void> => {
        switch (action) {
            case 'INFO':
                setDialogState({ show: true, device: device, mode: 'INFO' });
                break;
            case 'EDIT':
                setDialogState({ show: true, device: device, mode: 'EDIT' });
                break;
            case 'DASHBOARD':
                const dto: CreateUpdateDeviceDTO = {
                    display_name: device.display_name,
                    dashboard: !device.dashboard,
                    secret: device.secret,
                    humidity: device.humidity,
                    temperature: device.temperature
                }
                const result = await updateDeviceGQL(device.id, dto);
                if (result.success && result.device) {
                    dispatch(updateDevice(result.device));
                }
                break;
            case 'DELETE':
                setDialogState({ show: true, device: device, mode: 'DELETE' });
                break;
            default:
                break
        }
    }

    return (
        <>
            <div className={`no-print z-10 sm:border-l border-gray-300 dark:border-gray-700 p-3 fixed top-0 right-0 bottom-0 h-full pt-16 bg-white dark:bg-gray-800 shadow-2xl transition-all overflow-x-hidden drawer ${show ? 'w-full sm:w-6/12 md:w-4/12 xl:w-2/12 show' : 'hide'}`}>
                <div className="flex flex-row-reverse items-center justify-between text-gray-500 dark:text-white pt-2">
                    <ViewList size={20} />
                    <div className="flex-grow text-right pr-2 font-medium ">لیست دستگاه ها</div>
                    <div className="w-8 h-8 bg-gray-200 dark:bg-gray-700 dark:text-gray-200 hover:text-white cursor-pointer hover:bg-blue-500 dark:hover:bg-blue-600 dark:hover:text-white rounded-full transition-all flex items-center justify-center p-1"
                        onClick={() => {
                            setNewDevice(true);
                        }}>
                        <Plus size={24} />
                    </div>
                </div>
                <div className="pt-2">
                    {
                        devicesState.devices.map((device: Device, index: number) => (
                            <DeviceItem key={index} device={device} onActionClick={onDeviceAction} />
                        ))
                    }
                </div>
            </div>
            <NewDeviceDialog
                show={newDevice}
                onClose={() => { setNewDevice(false) }}
                onSave={() => { setNewDevice(false) }} />
            {
                dialogState.device ?
                    <>
                        <DeviceInfoDialog
                            show={dialogState.mode === 'INFO' ? dialogState.show : false}
                            device={dialogState.device}
                            onClose={() => { setDialogState(initialState) }} />
                        <EditDeviceDialog
                            show={dialogState.mode === 'EDIT' ? dialogState.show : false}
                            device={dialogState.device}
                            onClose={() => { setDialogState(initialState) }}
                            onSave={() => { }} />
                        <RemoveDeviceDialog
                            show={dialogState.mode === 'DELETE' ? dialogState.show : false}
                            device={dialogState.device}
                            onClose={() => { setDialogState(initialState) }}
                            onOKPress={async (device: Device) => {
                                const result = await removeDeviceGQL(device.id);
                                if (result.success) {
                                    dispatch(removeDevice(device.id));
                                    setDialogState(initialState);
                                }
                            }} />
                    </> : null
            }
        </>
    )
}