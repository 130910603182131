import { Dialog, Transition } from '@headlessui/react'
import { ClipboardCopyIcon, InformationCircleIcon } from '@heroicons/react/outline';
import copy from 'copy-to-clipboard';
import { Fragment, useEffect, useState } from 'react'
import { Device } from '../../interfaces/device';
import NotificationAlert from '../NotificationAlert';

interface Props {
    show: boolean;
    device: Device;
    onClose: () => void;
}

interface NotificationShow {
    show: boolean;
    icon: React.ReactNode;
    body: string;
}

const initialNotification: NotificationShow = {
    show: false,
    icon: <ClipboardCopyIcon className="w-6 h-6" />,
    body: 'مقدار مورد نظر کپی شد.'
}

export default function DeviceInfoDialog(props: Props) {

    const [not, setNot] = useState<NotificationShow>(initialNotification);

    useEffect(() => {
        if (props.show) {
            document.documentElement.classList.add('no-scroll');
        } else {
            document.documentElement.classList.remove('no-scroll');
        }
    });

    return (
        <>
            <Transition
                show={props.show}
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0">
                <Dialog
                    as="div"
                    className="fixed inset-0 z-40 overflow-y-auto"
                    onClose={props.onClose}>
                    <Dialog.Overlay className="fixed inset-0 bg-gray-700 opacity-30 blur" />
                    <div className="min-h-screen px-1 sm:px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0">
                            <Dialog.Overlay className="fixed inset-0" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95">
                            <div className="inline-block w-full max-w-md p-3 my-8 overflow-hidden text-left align-middle transition-all transform bg-white dark:bg-gray-900 shadow-xl rounded-2xl">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6">
                                    <div className="flex flex-row-reverse space-x-2 items-center text-blue-500 dark:text-blue-400">
                                        <InformationCircleIcon className="w-7 h-7" />
                                        <div className={`pr-2 font-light py-2`}>{props.device?.display_name}</div>
                                    </div>
                                </Dialog.Title>
                                <div className="py-6">
                                    <div className="pb-6 text-gray-500 dark:text-gray-300">
                                        <div className="font-mono text-sm pb-1">Username & Client ID</div>
                                        <div className="flex space-x-1 sm:space-x-2 items-center justify-start cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-800 rounded-xl py-2 px-1"
                                            onClick={() => {
                                                copy(props.device ? `device@${props.device.id}` : '');
                                                setNot({ ...not, show: true, body: 'مقدار Username & Client ID کپی شد.' });
                                            }}>
                                            <div className="cursor-pointer text-yellow-500 hover:text-yellow-600 rounded-full flex items-center justify-center">
                                                <ClipboardCopyIcon className="w-6 h-6" />
                                            </div>
                                            <div className="font-bold font-mono dark:text-white sl-ellipsis">device@{props.device?.id}</div>
                                        </div>
                                    </div>
                                    <div className="pb-6 text-gray-500 dark:text-gray-300">
                                        <div className="font-mono text-sm pb-1">Password</div>
                                        <div className="flex space-x-1 sm:space-x-2 items-center justify-start cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-800 rounded-xl py-2 px-1"
                                            onClick={() => {
                                                copy(props.device ? props.device.secret : '');
                                                setNot({ ...not, show: true, body: 'مقدار Password کپی شد.' });
                                            }}>
                                            <div className="cursor-pointer text-yellow-500 hover:text-yellow-600 rounded-full flex items-center justify-center">
                                                <ClipboardCopyIcon className="w-6 h-6 " />
                                            </div>
                                            <div className="font-bold font-mono dark:text-white">{props.device?.secret}</div>
                                        </div>
                                    </div>
                                    <div className="text-gray-500 dark:text-gray-300">
                                        <div className="font-mono text-sm pb-1">MQTT Publish Topic</div>
                                        <div className="flex space-x-1 sm:space-x-2 items-center justify-start cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-800 rounded-xl py-2 px-1"
                                            onClick={() => {
                                                copy(props.device ? `HadronCloud/${props.device?.account_id}/${props.device?.id}` : '');
                                                setNot({ ...not, show: true, body: 'مقدار Topic کپی شد.' });
                                            }}>
                                            <div className="cursor-pointer text-yellow-500 hover:text-yellow-600 rounded-full flex items-center justify-center">
                                                <ClipboardCopyIcon className="w-6 h-6 " />
                                            </div>
                                            <div className="sl-ellipsis font-bold font-mono dark:text-white">HadronCloud/{props.device?.account_id}/{props.device?.id}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-4 flex space-x-2">
                                    <button
                                        type="button"
                                        className="inline-flex justify-center px-4 py-2 border border-transparent rounded-xl focus:outline-none text-gray-500 dark:text-gray-400 hover:text-gray-800 dark:hover:text-white hover:bg-gray-100 dark:hover:bg-gray-800 transition-all"
                                        onClick={props.onClose}>
                                        متوجه شدم
                                    </button>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
            <NotificationAlert show={not.show} onClose={() => {
                setNot(initialNotification);
            }} body={not.body} />
        </>
    )
}