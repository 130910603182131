import { Transition } from '@headlessui/react'
import { ClipboardCopyIcon } from '@heroicons/react/outline';
import { useEffect } from 'react';
import { setTimeout } from 'timers';

interface Props {
    icon?: React.ReactNode;
    body: string;
    show: boolean;
    onClose: () => void
}

export default function NotificationAlert(props: Props) {
    // each time that show props is true : after 3 sec call props on close to auto close notification
    useEffect(() => {
        if (props.show) {
            setTimeout(() => {
                props.onClose()
            }, 3000);
        }
    });

    return (
        <Transition
            show={props.show}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0">
            <div className="z-50 fixed inset-x-2 top-12 sm:inset-x-auto sm:right-12">
                <div className="bg-indigo-500 text-indigo-50 shadow-md rounded-2xl p-2 px-6 flex space-x-2 items-top justify-end">
                    <div className="pt-1 rtl">{props.body}</div>
                    <ClipboardCopyIcon className="w-6 h-6" />
                </div>
            </div>
        </Transition>
    )
}