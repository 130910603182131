import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useEffect } from 'react'
import { REPORT_TARGET } from '../../graphql/reports/dto';

export interface ReportTargetSelectorItem {
    id: number;
    target: REPORT_TARGET;
    name: string;
}

export const ReportTargetSeclectors: Array<ReportTargetSelectorItem> = [
    { id: 0, name: 'دما و رطوبت', target: REPORT_TARGET.BOTH },
    { id: 1, name: 'دما', target: REPORT_TARGET.TEMPERATURE },
    { id: 2, name: 'رطوبت', target: REPORT_TARGET.HUMIDITY },
]

interface Props {
    show: boolean;
    onClose: () => void;
    onSelectedChanged(to: ReportTargetSelectorItem): void;
}

export default function TargetSelectorModal(props: Props) {

    useEffect(() => {
        if (props.show) {
            document.documentElement.classList.add('no-scroll');
        } else {
            document.documentElement.classList.remove('no-scroll');
        }
    });

    return (
        <>
            <Transition
                show={props.show}
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0">
                <Dialog
                    as="div"
                    className="fixed inset-0 z-40 overflow-y-auto"
                    onClose={props.onClose}>
                    <Dialog.Overlay className="fixed inset-0 bg-gray-700 opacity-30 blur" />
                    <div className="min-h-screen px-1 sm:px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0">
                            <Dialog.Overlay className="fixed inset-0" />
                        </Transition.Child>

                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true" />

                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95">
                            <div className="inline-block w-full max-w-md p-3 my-8 overflow-hidden text-left align-middle transition-all transform bg-white dark:bg-gray-900 shadow-xl rounded-2xl">
                                <div className="flex flex-col items-stretch">
                                    {
                                        ReportTargetSeclectors.map((element) => (
                                            <div className="text-right font-medium p-2 my-1 cursor-pointer hover:bg-blue-500 dark:hover:bg-blue-500 hover:text-white dark:hover:text-white rounded-xl"
                                                key={element.id}
                                                onClick={() => {
                                                    props.onSelectedChanged(element);
                                                }}>
                                                {element.name}
                                            </div>
                                        ))
                                    }
                                </div>
                                <div className="mt-4 flex space-x-2">
                                    <button
                                        type="button"
                                        className="inline-flex justify-center px-4 py-2 border border-transparent rounded-xl focus:outline-none text-gray-500 dark:text-gray-400 hover:text-gray-800 dark:hover:text-white hover:bg-gray-100 dark:hover:bg-gray-800 transition-all"
                                        onClick={props.onClose}>
                                        انصراف
                                    </button>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}