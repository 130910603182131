import { AnyAction } from "redux";
import { RootState } from "..";
import { AuthorizationInfo } from "../../interfaces/auth";
import { AUTH_TOKENS_KEY } from "../../types";

// declear redux action types name for authorization actions
export enum AuthorizationActionTypes {
    STORE = '@@authorization/STORE',
    REMOVE = '@@authorization/REMOVE'
}

// declear authorization state selector function
export const selectAuthorization = (rootState: RootState) =>
    rootState.authorization;

// function that returns initial state based on local storage authorization info
export const authorizationInitialState = (): AuthorizationInfo => {
    const data = localStorage.getItem(AUTH_TOKENS_KEY);
    if (data) {
        const auth = JSON.parse(data) as AuthorizationInfo
        return auth
    }
    return {
        token: '',
        refresh: '',
        expire_at: new Date()
    }
}

export const authorizationReducer = (
    state: AuthorizationInfo = authorizationInitialState(),
    action: AnyAction): AuthorizationInfo => {

    switch (action.type) {
        case AuthorizationActionTypes.STORE:
            localStorage.setItem(AUTH_TOKENS_KEY, JSON.stringify(action.payload as AuthorizationInfo))
            return action.payload as AuthorizationInfo
        case AuthorizationActionTypes.REMOVE:
            localStorage.setItem(AUTH_TOKENS_KEY, JSON.stringify({
                token: '',
                refresh: '',
                expire_at: new Date()
            } as AuthorizationInfo));

            return {
                token: '',
                refresh: '',
                expire_at: new Date()
            }
        default:
            return state
    }
}