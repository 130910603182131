import React from 'react';
import { Route, Redirect } from "react-router-dom";
import { useSelector } from 'react-redux';
import { selectAuthorization } from '../../store/authorization';

const PublicOnlyRoute = ({ component, isAuthenticated, ...rest }: any) => {
    const authorization = useSelector(selectAuthorization);
    const routeComponent = (props: any) => (
        authorization.token === '' || authorization.expire_at < new Date()
            ? React.createElement(component, props)
            : <Redirect to={{ pathname: '/' }} />
    );
    return <Route {...rest} render={routeComponent} />;
};
export default PublicOnlyRoute;