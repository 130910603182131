import { client } from "../client";
import { GQL_ACCOUNT, GQL_LOGIN, GQL_VERIFY } from "../data";
import GraphQLBaseResult from "../gql-base.dto";
import { AccountResultDTO, LoginInputDTO, VerifyInputDTO, VerifyResultDTO } from "./dto";


export async function loginGQL(input: LoginInputDTO): Promise<GraphQLBaseResult> {
    return new Promise(async (resolve) => {
        try {
            const response = await client.mutate({
                mutation: GQL_LOGIN,
                variables: { input: input },
            });
            if (response.data) {
                resolve(response.data.login as GraphQLBaseResult);
            }
        } catch (error) {
            console.log('ERROR: ', error);
        }
    });
}

export async function verifyGQL(input: VerifyInputDTO): Promise<VerifyResultDTO> {
    return new Promise(async (resolve) => {
        try {
            const response = await client.mutate({
                mutation: GQL_VERIFY,
                variables: { input: input },
            });
            if (response.data) {
                resolve(response.data.verify as VerifyResultDTO);
            }
        } catch (error) {
            console.log('ERROR: ', error);
        }
    });
}

export async function accountGQL(): Promise<AccountResultDTO> {
    return new Promise(async (resolve) => {
        try {
            const response = await client.query({
                query: GQL_ACCOUNT
            });
            if (response.data) {
                resolve(response.data.account as AccountResultDTO);
            }
        } catch (error) {
            console.log('ERROR: ', error);
        }
    });
}