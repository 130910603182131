import { useContext, useState } from 'react';
import AlertDialog from '../components/AlertDialog';
import { LoadingProviderContext } from '../contexts/loading.context';
import { LoginInputDTO, ROLES, VerifyInputDTO } from '../graphql/user/dto';
import { loginGQL, verifyGQL } from '../graphql/user/handlers';
import { Regexes } from '../lib/regexes';
import { BeakerIcon } from '@heroicons/react/solid';
import * as loginConstants from '../types/login.messages';
import VerificationInput from 'react-verification-input';
import { useHistory } from 'react-router-dom';
import { ThreeDotsVertical } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux'
import { storeAuthorization } from '../store/authorization/action';

// @OBJECT for holding user input states
export interface AuthUserInput {
    email: string;
    error: boolean;
}

interface Modal {
    title: string;
    icon: React.ReactNode;
    body: string;
    show: boolean;
}

export default function LoginPage() {
    const loginModalTitle: string = 'ورود به حساب کاربری';
    const verifyModalTitle: string = 'تایید شماره موبایل';

    const dispatch = useDispatch();

    // instance of browser history for redirection to dashjboard home after verification success
    const history = useHistory();
    // state for handling whene verifucation not expired or it expired and must re login
    const [nextMode, setNextMode] = useState<string>('');

    // state for user input
    const [input, setInput] = useState<AuthUserInput>({ email: '', error: false });
    // state for loading context
    const { setLoading } = useContext(LoadingProviderContext);
    // state for authorization context
    // const { setAuthorization } = useContext(AuthorizationProviderContext);
    // sate for show verification or login inputs
    const [verifyMode, setVerifyMode] = useState<boolean>(false);

    const [modal, setModal] = useState<Modal>({
        title: '',
        icon: null,
        body: '',
        show: false
    })

    // handling email input onChange event
    const handleEmailInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value: string = e.currentTarget.value.trim().toLowerCase();
        // control if email is empty remove error for UX experinces
        if (value === '') {
            setInput({ error: false, email: '' })
        } else {
            // if email is not empty validate user input
            if (Regexes.EMAIL.test(value.toLowerCase())) {
                // set input object state 1: enable login button, 2: remove error
                setInput({ email: value.toLowerCase(), error: false })
            } else {
                // set input object state 1: disable login button, 2: show input error
                setInput({ email: '', error: true });
            }
        }
    }

    // handling user touch or click on login button
    const handleLogin = async () => {
        setLoading(true);
        const dto: LoginInputDTO = {
            email: input.email.toLowerCase(),
            role: ROLES.USER
        }

        const result = await loginGQL(dto);
        setLoading(false);

        switch (result.code) {
            case loginConstants.C_VERIFICATION_CODE_NOT_EXPIRED:
                setModal({
                    show: true,
                    title: loginModalTitle,
                    body: loginConstants.M_VERIFICATION_CODE_NOT_EXPIRED,
                    icon: <BeakerIcon className="h-8 w-8 text-blue-600" />
                });
                setNextMode('verify');
                break;
            case loginConstants.C_VERIFICATION_CODE_RESENT_SUCCESS:
            case loginConstants.C_VERIFICATION_CODE_SENT_SUCCESS:
                setVerifyMode(true);
                break;
            case loginConstants.C_INTERNAL_ERROR_ON_UPDATE_VERIFICATION_CODE:
                setModal({
                    show: true,
                    title: loginModalTitle,
                    body: loginConstants.M_INTERNAL_ERROR_ON_UPDATE_VERIFICATION_CODE,
                    icon: <BeakerIcon className="h-8 w-8 text-blue-600" />
                });
                break;
            case loginConstants.C_INVALID_USER_ROLE:
                setModal({
                    show: true,
                    title: loginModalTitle,
                    body: loginConstants.M_INVALID_USER_ROLE,
                    icon: <BeakerIcon className="h-8 w-8 text-blue-600" />
                });
                break;
        }
    }

    const handleVerify = async (value: string) => {
        if (!Regexes.VERIFICATION_CODE.test(value)) {
            return
        }

        setLoading(true);
        const dto: VerifyInputDTO = {
            email: input.email.toLowerCase(),
            role: ROLES.USER,
            code: value
        }

        const result = await verifyGQL(dto);
        setLoading(false);

        switch (result.code) {
            case loginConstants.C_INVALID_VERIFICATION:
                setModal({
                    show: true,
                    title: verifyModalTitle,
                    body: loginConstants.M_INVALID_VERIFICATION,
                    icon: <BeakerIcon className="h-8 w-8 text-blue-600" />
                });
                break;
            case loginConstants.C_VERIFICATION_EXPIRED:
                setModal({
                    show: true,
                    title: verifyModalTitle,
                    body: loginConstants.M_VERIFICATION_EXPIRED,
                    icon: <BeakerIcon className="h-8 w-8 text-blue-600" />
                });
                setNextMode('login');
                break;
            case loginConstants.C_VERIFICATION_SUCCESS:
                dispatch(storeAuthorization({
                    token: result.token,
                    refresh: result.refresh,
                    expire_at: result.expire_at
                }))
                // setAuthorization();
                setTimeout(() => {
                    setLoading(false);
                    history.push('/');
                }, 1000)
                break;
        }
    }

    const alertOnOk = () => {
        switch (nextMode) {
            case 'login':
                setVerifyMode(false);
                setNextMode('');
                setModal({
                    show: false,
                    title: '',
                    body: '',
                    icon: null
                });
                break;
            case 'verify':
                setVerifyMode(true);
                setNextMode('');
                setModal({
                    show: false,
                    title: '',
                    body: '',
                    icon: null
                });
                break;
        }
    }

    const alertClose = () => {
        setNextMode('');
        setModal({
            show: false,
            title: '',
            body: '',
            icon: null
        })
    }

    // function that returns alert primary text based on login and verify result
    const getAlertPrimaryText = (): string => {
        switch (nextMode) {
            case 'login':
                return 'ورود مجدد';
            case 'verify':
                return 'وارد کردن کد تایید';
        }
        return '';
    }

    return (
        <>

            <div className={`flex flex-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/4 pt-4 items-center mx-auto px-4 md:px-2 lg:px-0`}>
                <div className="signup-image w-full" />
                <h5 className="text-xl font-bold text-center pt-5 text-blue-500 dark:text-white">
                    {
                        verifyMode ? 'کد تایید ایمیل' : 'ورود / ثبت نام'
                    }
                </h5>
                <h5 className="pb-8 pt-4 rtl text-center">
                    {
                        verifyMode ? 'کد ۶ رقمی ارسال شده به ایمل خود را وارد نمایید' : 'لطفا آدرس ایمیل خود را جهت ورود به پنل دیتا لاگر آنلاین وارد نمایید'
                    }
                </h5>
                {verifyMode ?
                    <VerificationInput
                        autoFocus={true}
                        validChars='0-9'
                        removeDefaultStyles
                        placeholder=''
                        classNames={{
                            container: "flex justify-center space-x-1 sm:space-x-2 h-11 font-medium text-gray-600 dark:text-white",
                            character: "verify-character w-10 border-2 border-gray-300 dark:border-gray-600 flex items-center justify-center text-2xl rounded-xl pt-1",
                            characterInactive: "border-transparent bg-gray-200 dark:bg-gray-700",
                            characterSelected: "border-blue-500 dark:border-blue-500",
                        }}
                        onChange={handleVerify}
                    /> :
                    <>
                        <input
                            id="email_input"
                            placeholder="mail@example.com"
                            className={`text-md bg-gray-200 focus:bg-gray-100 dark:focus:bg-gray-600 dark:bg-gray-700 focus:shadow-md rounded-full py-3 px-5 w-full outline-none border transition-all ${input.error ? 'border-red-500' : 'border-gray-200 dark:border-gray-800 dark:focus:border-gray-900 focus:border-gray-300'}`}
                            type="text"
                            onChange={handleEmailInput} />
                        <button
                            className={`text-md rounded-full py-2 px-8 mt-6 bg-blue-500 text-white font-medium transition-all ${input.email !== '' ? 'hover:shadow-md hover:bg-blue-600' : 'opacity-50 blur'}`}
                            onClick={handleLogin}>ورود/ثبت نام</button>
                    </>
                }
            </div>
            <AlertDialog
                title={modal.title}
                body={modal.body}
                show={modal.show}
                icon={modal.icon}
                onClose={alertClose}
                primaryText={getAlertPrimaryText()}
                primaryOnClick={alertOnOk}
                secondaryOnClick={alertClose} />
            <footer className="pt-24 pb-4 px-2">
                <div className="flex justify-center items-center space-x-2 text-sm">
                    <a className="text-gray-500 dark:text-gray-400 hover:text-blue-500 dark:hover:text-white" href="/">پشتیبانی</a>
                    <ThreeDotsVertical size={10} className="text-gray-400" />
                    <a className="text-gray-500 dark:text-gray-400 hover:text-blue-500 dark:hover:text-white" href="/">پشتیبانی</a>
                </div>
                <div className="rtl text-sm text-center pt-4">
                    تمامی حقوق این سایت مطعلق به شرکت بهپویا میباشد
                </div>
            </footer>
        </>
    )
}