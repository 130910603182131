import GraphQLBaseResult from "../gql-base.dto";
import { SORT } from "../types";

export enum REPORT_TARGET {
    HUMIDITY = 'HUMIDITY',
    TEMPERATURE = 'TEMPERATURE',
    BOTH = 'BOTH'
}

export interface ReportsInputDTO {
    device_id: string;
    after?: string;
    before?: string;
    sort: SORT;
    target: REPORT_TARGET;
}

export interface ReportsOutputDTO extends GraphQLBaseResult {
    size: number;
    reports: Array<ReportItem>;
}

export interface ReportItem {
    id: string;
    device_id: string;
    target: REPORT_TARGET;
    packets: number;
    max_value: number;
    min_value: number;
    closed: boolean;
    created_at: Date;
    updated_at: Date;
}