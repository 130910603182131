import { useContext, useEffect, useState } from 'react';
import { List, Power, X } from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';

import ThemeToggle from './ThemeToggle';
import Logo from '../../assets/images/logo.svg';
import Drawer from './Drawer';
import { DrawerProviderContext } from '../../contexts/drawer.context';
import { selectAuthorization } from '../../store/authorization';
import SignOutDialog from '../SignOutDialog';
import { storeAuthorization } from '../../store/authorization/action';
import { removeDevices } from '../../store/devices/action';
import { mqttDisconnect } from '../../store/mqtt/action';

// @OBJECT for Header Navigation Props
interface Props {
    drawerClick?: () => void;
}

export default function HeaderNav(props: Props) {
    // drawer visibility state
    const { show, setShow } = useContext(DrawerProviderContext);

    // read authorization info from local storage
    const authorization = useSelector(selectAuthorization);

    // user authorization state
    const [authorized, setAuthorized] = useState<boolean>(false);

    // user sign out dependecies for redux and dialog
    const [signOutDialog, setSignOutDialog] = useState<boolean>(false);
    const dispatch = useDispatch();
    const signOut = (): void => {
        // 1 : remove user information and tokens
        dispatch(storeAuthorization({ token: '', refresh: '', expire_at: new Date() }));
        dispatch(removeDevices());
        localStorage.clear();
        mqttDisconnect();
        setSignOutDialog(false);
        setAuthorized(false);
    }

    // before mounting component checking whether user authorized or no
    useEffect(() => {
        if (authorization) {
            if (authorization.token !== '' && authorization.refresh !== '' && authorization.expire_at > new Date()) {
                setAuthorized(true);
            }
        }
    }, [setAuthorized, authorization]);

    return (
        <>
            <nav className="no-print z-20 border-b border-gray-300 dark:border-black dark:border-opacity-50 flex flex-row-reverse fixed left-0 right-0 items-center bg-white dark:bg-gray-900 py-2 shadow-md">
                {
                    authorized ?
                        <div className={`mr-2 flex justify-center items-center h-10 w-10 rounded-full p-1 transition-all cursor-pointer ${show ? 'bg-gray-100 dark:bg-gray-800 text-blue-500' : 'hover:bg-gray-100 hover:text-blue-600 dark:hover:bg-gray-800 dark:hover:text-blue-500'}`}
                            onClick={() => {
                                setShow(!show);
                            }}>
                            {
                                show ? <X size={24} /> : <List size={24} />
                            }
                        </div> : null
                }
                <img src={Logo} className="h-7 object-fit my-1 mr-2" alt="Hadron" />
                <div className="flex-grow" />
                {
                    authorized ?
                        <div className="flex space-x-1 items-center justify-center px-2 ml-4 hover:text-red-500 cursor-pointer"
                            onClick={() => setSignOutDialog(true)}>
                            <div className="text-xs font-medium">خروج</div>
                            <Power size={20} />
                        </div> : null
                }
                <ThemeToggle />
            </nav >
            {
                authorized ? <>
                    <Drawer />
                    <SignOutDialog show={signOutDialog} onClose={() => setSignOutDialog(false)} onOKPress={signOut} />
                </> : null
            }
        </>
    )
}
