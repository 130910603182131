import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from '@apollo/client/link/context';
import { AuthorizationInfo } from "../interfaces/auth";
import { AUTH_TOKENS_KEY } from "../types";

const httpLink = createHttpLink({
    uri: 'https://api.hadrontec.ir/graphql'
});

const authLink = setContext((_, { headers }) => {
    let authData = localStorage.getItem(AUTH_TOKENS_KEY);
    const auth = authData ? JSON.parse(authData) as AuthorizationInfo : null;
    return auth ? {
        headers: {
            ...headers,
            authorization: `Bearer ${auth.token}`,
        }
    } : {
        headers: {
            ...headers,
            authorization: ''
        }
    };
});

export const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({
        addTypename: false
    }),
});