import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useEffect } from 'react'

interface Props {
    title: string;
    icon?: React.ReactNode;
    body: string;
    show: boolean;
    primaryText?: string;
    secondaryText?: string;
    primaryOnClick: () => void;
    secondaryOnClick?: () => void;
    onClose: () => void
}

export default function AlertDialog(props: Props) {
    
    useEffect(() => {
        if (props.show) {
            document.documentElement.classList.add('no-scroll');
        } else {
            document.documentElement.classList.remove('no-scroll');
        }
    });

    return (
        <Transition
            show={props.show}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0">
            <Dialog
                as="div"
                className="fixed inset-0 z-40 overflow-y-auto"
                onClose={props.onClose}>
                <Dialog.Overlay className="fixed inset-0 bg-gray-700 opacity-30 blur" />
                <div className="min-h-screen px-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0">
                        <Dialog.Overlay className="fixed inset-0" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                    >
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95">
                        <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white dark:bg-gray-900 shadow-xl rounded-2xl">
                            <Dialog.Title
                                as="h3"
                                className="text-lg font-medium leading-6">
                                <div className="flex flex-row-reverse space-x-2">
                                    {props.icon ? props.icon : null}
                                    <div className={`text-gray-500 dark:text-gray-300 ${props.icon ? 'pr-2' : ''}`}>{props.title}</div>
                                </div>
                            </Dialog.Title>
                            <div className="my-6 mb-2 text-right rtl text-gray-500 dark:text-gray-300">
                                {props.body}
                            </div>

                            <div className="mt-4 flex space-x-2">
                                <button
                                    type="button"
                                    className="inline-flex justify-center px-4 py-2 font-medium text-white bg-blue-500 border border-transparent rounded-xl hover:bg-blue-600 focus:outline-none transition-all"
                                    onClick={props.primaryOnClick}
                                >
                                    {props.primaryText ? props.primaryText : 'تایید'}
                                </button>
                                <button
                                    type="button"
                                    className="inline-flex justify-center px-4 py-2 border border-transparent rounded-xl focus:outline-none text-gray-500 dark:text-gray-400 hover:text-gray-800 dark:hover:text-white hover:bg-gray-100 dark:hover:bg-gray-800 transition-all"
                                    onClick={props.secondaryOnClick}
                                >
                                    {props.secondaryText ? props.secondaryText : 'انصراف'}
                                </button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    )
}