export class Regexes {
    public static readonly EMAIL = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i;
    public static readonly MOBILE = /^09(1[0-9]|3[1-9]|2[1-9])-?[0-9]{3}-?[0-9]{4}$/i;
    public static readonly VERIFICATION_CODE = /^[0-9]{6,6}$/i;
    public static readonly NAME_AND_FAMILY = /^[a-zA-Z\u0600-\u06FF]{1,1}[a-zA-Z0-9\u0600-\u06FF ]{1,127}[a-zA-Z0-9\u0600-\u06FF]{1,1}$|^$/i;
    public static readonly ADDRESS_NAME = /^[0-9\u0600-\u06FF ]{4,64}$/i;
    public static readonly ADDRESS = /^[0-9\u0600-\u06FF ().\-_]{4,256}$/i;
    public static readonly POSTAL_CODE = /^[0-9]{10,10}$/i;
    public static readonly PHONE = /^([0]{1,1})([0-9]{2,2})([0-9]{4,9})$/i;
    public static readonly DEVICE_NAME = /^[a-zA-Z0-9\u0600-\u06FF .-_]{4,72}$/i;
    public static readonly DEVICE_PASSWORD = /^[a-zA-Z0-9._\-@#%$&*]{8,32}$/i;
}