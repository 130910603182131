import { ArrowDownShort, ArrowRepeat, ArrowUpShort, ClockHistory, Droplet, ThermometerHalf } from "react-bootstrap-icons";
import moment from 'moment-jalaali';

import { ReportItem } from "../../graphql/reports/dto";

interface Props {
    index: number;
    data: ReportItem
}

export default function ReportItemRow(props: Props) {
    const diff = (start: Date, end: Date): string => {
        let diffInMilliSeconds = Math.abs(end.getTime() - start.getTime()) / 1000;

        const days = Math.floor(diffInMilliSeconds / 86400);
        diffInMilliSeconds -= days * 86400;

        const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
        diffInMilliSeconds -= hours * 3600;

        const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
        diffInMilliSeconds -= minutes * 60;
        let difference = '';
        if (days > 0) {
            difference += `${days} روز`;
        }
        if (hours > 0) {
            difference += days > 0 ? `و ${hours} ساعت` : `${hours} ساعت`;
        }
        difference += hours > 0 ? ` و ${minutes} دقیقه` : `${minutes} دقیقه`;
        return difference;
    }

    return (
        <div className="flex sm:space-x-2 px-1 sm:px-2 mb-1 sm:py-2 rounded-lg border bg-gray-50 bg-gray-850 border-transparent hover:bg-white dark:hover:bg-black hover:border-gray-200 dark:hover:border-gray-700 hover:shadow print-border">
            <div className="flex flex-grow sm:flex-grow-0 items-center justify-center py-1 px-1 sm:px-2 text-gray-400 dark:text-gray-500">
                <ArrowRepeat size={14} />
                <div className="text-xs pl-0.5 font-medium pt-1">{props.data.packets}</div>
            </div>
            <div className={`sm:flex sm:space-x-2 items-center justify-center py-1 px-1 sm:px-2`}>
                <div className="flex">
                    <ArrowUpShort size={18} className="text-yellow-600 dark:text-yellow-500" />
                    <div className="font-medium text-gray-500 dark:text-gray-400" >{props.data.max_value}</div>
                </div>
                <div className="flex">
                    <ArrowDownShort size={18} className="text-yellow-600 dark:text-yellow-500" />
                    <div className="font-medium text-gray-500 dark:text-gray-400" >{props.data.min_value}</div>
                </div>
            </div>
            <div className="flex-grow flex items-center justify-center">
                <div className="rtl font-medium text-gray-500 dark:text-gray-400 hidden sm:flex items-center justify-center">
                    <ClockHistory size={14} />
                    <div className="pr-2">{diff(new Date(props.data.updated_at), new Date(props.data.created_at))}</div>
                </div>
            </div>
            <div className={`sm:flex sm:space-x-2 items-center justify-center py-1 px-1 sm:px-2 ${props.data.closed ? 'text-green-500' : 'text-red-600'}`}>
                <div className="font-medium">{moment(props.data.updated_at).format('HH:mm:ss')}</div>
                <div>{moment(props.data.updated_at).format('jYYYY/jM/jD')}</div>
            </div>
            <div className="sm:flex sm:space-x-2 items-center justify-center py-1 px-1 sm:px-2 text-red-600">
                <div className="font-medium">{moment(props.data.created_at).format('HH:mm:ss')}</div>
                <div>{moment(props.data.created_at).format('jYYYY/jM/jD')}</div>
            </div>
            <div className="flex items-center justify-center p-1 sm:px-2 text-blue-500">
                {
                    props.data.target === 'HUMIDITY' ? <Droplet size={18} /> : <ThermometerHalf size={18} />
                }
            </div>
            <div className="flex items-center justify-center py-1 px-1 sm:px-2 font-bold text-gray-500 dark:text-gray-400">{props.index}</div>
        </div>
    )
}