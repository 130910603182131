import { DevicesActionTypes, DevicesState } from "."
import { AnyAction } from "redux"
import { Device } from "../../interfaces/device"

export const updateDevice = (device: Device): AnyAction => {
    return {
        type: DevicesActionTypes.UPDATE_DEVICE,
        payload: device
    }
}

export const createDevice = (device: Device): AnyAction => {
    return {
        type: DevicesActionTypes.CREATE_DEVICE,
        payload: device
    }
}

export const storeDevices = (devices: DevicesState): AnyAction => {
    return {
        type: DevicesActionTypes.STORE_DEVICES,
        payload: devices
    }
}

export const removeDevices = (): AnyAction => {
    return {
        type: DevicesActionTypes.REMOVE_DEVICES
    }
}

export const removeDevice = (id: string): AnyAction => {
    return {
        type: DevicesActionTypes.REMOVE_DEVICE,
        payload: id
    }
}